import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "g-row g-row--appearance_spaced" }
const _hoisted_2 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_3 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_4 = { class: "er-space_mt er-space_mt--0 er-space_mt--5-xl er-space_mb er-space_mb--5" }
const _hoisted_5 = { class: "g-row g-row--appearance_spaced" }
const _hoisted_6 = { class: "g-cell g-cols g-cols--12 g-cols--auto-lg" }
const _hoisted_7 = { class: "g-row g-row--appearance_spaced" }
const _hoisted_8 = { class: "g-cell g-cols g-cols--12 g-cols--narrow-lg" }
const _hoisted_9 = {
  key: 0,
  class: "er-tabs er-tabs--styling_primary"
}
const _hoisted_10 = { class: "er-tabs__head" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "g-cell g-cols g-cols--12 g-cols--narrow-lg" }
const _hoisted_13 = {
  key: 0,
  class: "er-tabs er-tabs--styling_primary"
}
const _hoisted_14 = { class: "er-tabs__head" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "g-cell g-cols g-cols--narrow" }
const _hoisted_17 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_18 = { class: "g-row g-row--appearance_nowrap" }
const _hoisted_19 = { class: "g-cell g-cols" }
const _hoisted_20 = ["href"]
const _hoisted_21 = { class: "table-action-item__hidden" }
const _hoisted_22 = ["href"]
const _hoisted_23 = { class: "table-action-item__hidden" }
const _hoisted_24 = ["href"]
const _hoisted_25 = { class: "table-action-item__hidden" }
const _hoisted_26 = ["href"]
const _hoisted_27 = { class: "table-action-item__hidden" }
const _hoisted_28 = {
  key: 0,
  class: "pagination-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ControlButton = _resolveComponent("ControlButton")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_UiPagination = _resolveComponent("UiPagination")!
  const _component_LoadingWrapper = _resolveComponent("LoadingWrapper")!

  return (_openBlock(), _createBlock($setup["CatalogPageFiltering"], {
    resource: $setup.resource,
    initial: $setup.initial
  }, {
    default: _withCtx((ctrl) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode($setup["UiLoadableFiltersView"], {
            filters: ctrl.filters,
            initial: $setup.initial,
            permanent: $setup.permanentFilters,
            "onUpdate:filters": ctrl.changeFilters,
            "list-resource": $setup.filterResource,
            displayed: ['delivery_range', 'schedule_price_avg_range', 'activity_range', 'companies']
          }, {
            append: _withCtx(({ allFilters, filters, value }) => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          (allFilters.pricing_types)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                _createElementVNode("div", _hoisted_10, _toDisplayString(allFilters.pricing_types.caption) + ":", 1),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.PricingTypesTitles, (caption, id) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: _normalizeClass(["er-tabs__tab", { 'is-active': ctrl.filters.filters?.pricing_types?.some(el => el.id === id)}])
                                  }, [
                                    _createElementVNode("button", {
                                      class: "er-tabs__value",
                                      type: "button",
                                      onClick: _withModifiers(() => {                                $setup.currentPricingType = { caption, id };                                ctrl.changeFilters({ filters: { ...$setup.permanentFilters.filters, ...ctrl.filters.filters, pricing_types: [{ caption, id }] }});                              }, ["prevent"])
                                    }, _toDisplayString(caption), 9, _hoisted_11)
                                  ], 2))
                                }), 256))
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_12, [
                          (allFilters.match_types)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                _createElementVNode("div", _hoisted_14, _toDisplayString(allFilters.match_types.caption) + ":", 1),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.MatchTypesTitles, (caption, id) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: _normalizeClass(["er-tabs__tab", { 'is-active': ctrl.filters.filters?.match_types?.some(el => el.id === id)}])
                                  }, [
                                    _createElementVNode("button", {
                                      class: "er-tabs__value",
                                      type: "button",
                                      onClick: _withModifiers(() => {                                $setup.currentPricingType = { caption, id };                                ctrl.changeFilters({ filters: { ...$setup.permanentFilters.filters, ...ctrl.filters.filters, match_types: [{ caption, id }] }});                              }, ["prevent"])
                                    }, _toDisplayString(caption), 9, _hoisted_15)
                                  ], 2))
                                }), 256))
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      ($setup.props.marketType === 'buy')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (!$setup.IS_AUTH)
                              ? (_openBlock(), _createBlock(_component_ControlButton, {
                                  key: 0,
                                  tag: "a",
                                  href: $setup.login_url,
                                  styling: "primary",
                                  size: "lg"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("Створити заявку")), 1)
                                  ]),
                                  _: 1
                                }, 8, ["href"]))
                              : ($setup.canCreateApplication)
                                ? (_openBlock(), _createBlock(_component_ControlButton, {
                                    key: 1,
                                    tag: "a",
                                    href: $setup.router.resolve({ name: $setup.APPLICATIONS_CREATE }).fullPath,
                                    styling: "primary",
                                    size: "lg"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t("Створити заявку")), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["href"]))
                                : _createCommentVNode("", true)
                          ], 64))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            (!$setup.IS_AUTH)
                              ? (_openBlock(), _createBlock(_component_ControlButton, {
                                  key: 0,
                                  tag: "a",
                                  href: $setup.login_url,
                                  styling: "primary",
                                  size: "lg"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("Створити пропозицію")), 1)
                                  ]),
                                  _: 1
                                }, 8, ["href"]))
                              : ($setup.canCreateOffers)
                                ? (_openBlock(), _createBlock(_component_ControlButton, {
                                    key: 1,
                                    tag: "a",
                                    href: $setup.router.resolve({ name: $setup.OFFERS_CREATE }).fullPath,
                                    styling: "primary",
                                    size: "lg"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t("Створити пропозицію")), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["href"]))
                                : _createCommentVNode("", true)
                          ], 64))
                    ])
                  ])
                ])
              ])
            ]),
            _: 2
          }, 1032, ["filters", "initial", "permanent", "onUpdate:filters", "list-resource"])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createVNode(_component_LoadingWrapper, {
            loading: ctrl.loading
          }, {
            default: _withCtx(() => [
              _createVNode($setup["OrdersDisplayList"], {
                "market-type": $props.marketType,
                "filter-controller": ctrl
              }, {
                "cell-action": _withCtx(({ value, item }) => [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, [
                      ($setup.props.marketType === 'buy')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (!$setup.IS_AUTH)
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 0,
                                  class: "table-action-item table-action-item--styling_primary",
                                  href: $setup.login_url
                                }, [
                                  _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t("Створити пропозицію")), 1),
                                  _createVNode(_component_AppIcon, {
                                    name: "i-plus-square",
                                    size: "xl"
                                  })
                                ], 8, _hoisted_20))
                              : ($setup.canCreateOffers)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 1,
                                    class: "table-action-item table-action-item--styling_primary",
                                    href: $setup.router.resolve({ name: $setup.OFFERS_CREATE, query: { application: item.id } }).fullPath
                                  }, [
                                    _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t("Створити пропозицію")), 1),
                                    _createVNode(_component_AppIcon, {
                                      name: "i-plus-square",
                                      size: "xl"
                                    })
                                  ], 8, _hoisted_22))
                                : _createCommentVNode("", true)
                          ], 64))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            (!$setup.IS_AUTH)
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 0,
                                  class: "table-action-item table-action-item--styling_primary",
                                  href: $setup.login_url
                                }, [
                                  _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$t("Створити заявку")), 1),
                                  _createVNode(_component_AppIcon, {
                                    name: "i-plus-square",
                                    size: "xl"
                                  })
                                ], 8, _hoisted_24))
                              : ($setup.canCreateApplication)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 1,
                                    class: "table-action-item table-action-item--styling_primary",
                                    href: $setup.router.resolve({ name: $setup.APPLICATIONS_CREATE, query: { offer: item.id } }).fullPath
                                  }, [
                                    _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.$t("Створити заявку")), 1),
                                    _createVNode(_component_AppIcon, {
                                      name: "i-plus-square",
                                      size: "xl"
                                    })
                                  ], 8, _hoisted_26))
                                : _createCommentVNode("", true)
                          ], 64))
                    ])
                  ])
                ]),
                _: 2
              }, 1032, ["market-type", "filter-controller"]),
              (ctrl.pagination && ctrl.pagination.total > ctrl.pagination.limit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                    _createVNode(_component_UiPagination, _mergeProps({
                      onInput: ctrl.changePagination
                    }, ctrl.pagination), null, 16, ["onInput"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["loading"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["resource", "initial"]))
}