import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "g-row g-row--appearance_spaced g-row--space_xl g-row--justify_center" }
const _hoisted_2 = {
  key: 0,
  class: "g-cell g-cols g-cols--12"
}
const _hoisted_3 = { class: "ds-caption ds-caption--color_8 ds-caption--size_lg ds-caption--size_2xl-md ds-caption--appearance_bold ds-caption--appearance_center" }
const _hoisted_4 = {
  key: 1,
  class: "g-cell g-cols g-cols--12"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 2,
  class: "g-cell g-cols"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ControlButton = _resolveComponent("ControlButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.message.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString($props.message.title), 1)
        ]))
      : _createCommentVNode("", true),
    ($props.message.text)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          ($props.message.text)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: "ds-caption ds-caption--size_md ds-caption--appearance_center ds-caption--appearance_wrapped",
                innerHTML: $props.message.text
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    ($props.closeBtn)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_ControlButton, {
            type: "button",
            styling: "primary",
            size: ['lg'],
            variant: "outlined",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($props.close()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("Закрити")), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}