import { App, defineAsyncComponent } from 'vue'

import ESign from '@/components/forms/ESign'

const ContactForm = defineAsyncComponent(
  () => import('@/components/forms/ContactForm.vue')
)
const ContactModalForm = defineAsyncComponent(
  () => import('@/components/forms/ContactModalForm.vue')
)

const install = (app: App<Element>): void => {
  const prefix = 'Form'
  app
    .component(`Contact${prefix}`, ContactForm)
    .component(`ContactModal${prefix}`, ContactModalForm)
    .use(ESign)
}

export default {
  install,
}