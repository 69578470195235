import { r, createSender } from '@/plugins/resource/resource'
import { queryPostfix as q } from '@/plugins/resource/api'

const MODEL = 'notifications'

const NOTIFICATIONS_MODEL = MODEL + '/notifications'
const NOTIFICATIONS_SETTINGS_MODEL = MODEL + '/settings'

export const notificationsApi = {
  flags: r('/flags/list/', MODEL),
  notifications: {
    changeFlags: r('/change-flags/{?ids}', NOTIFICATIONS_MODEL, createSender),
    clear: r('/clear/', NOTIFICATIONS_MODEL, createSender),
    list: r(q('/list/', ['flags']), NOTIFICATIONS_MODEL),
    stats: r('/stats/', NOTIFICATIONS_MODEL),
  },
  settings: {
    retrieve: r('/', NOTIFICATIONS_SETTINGS_MODEL),
    update: r('/update/', NOTIFICATIONS_SETTINGS_MODEL, createSender).config(
      'method',
      'PATCH'
    ),
  },
}
