import type { RouteRecordRaw } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'
import { ordersApi } from '@/services/orders.service'
import { receiveRoute } from '@m/cabinet/components/views/utils'
// import { receiveRoute } from '@m/cabinet/components/views/utils'
// import { communicationApi } from '@/services/communication.service'

const PREFIX = 'cabinet:templates'
const p = (name: string) => PREFIX + ':' + name

export const TEMPLATES_CREATE = p('create')
export const TEMPLATES_LIST = p('list')
export const TEMPLATES_EDIT = p('edit')

const Create = () => import('./views/Create.vue')
const Edit = () => import('./views/Edit.vue')
const List = () => import('./views/List/index.vue')

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route('list/', List, TEMPLATES_LIST, {
      meta: {
        title: () => $t('Шаблони пропозицій/заявок'),
      },
    }),

    receiveRoute(ordersApi.templates.retrieve, [
      route('edit/', Edit, TEMPLATES_EDIT, {
        meta: {
          title: () => $t('Редагування шаблонів'),
          fallbackRouteName: TEMPLATES_LIST,
        },
      }),
      { path: '', redirect: { name: TEMPLATES_EDIT } },
    ]),
    route('create/', Create, TEMPLATES_CREATE, {
      meta: {
        title: () => $t('Створення шаблонів'),
        fallbackRouteName: TEMPLATES_LIST,
      },
    }),
    { path: '', redirect: { name: TEMPLATES_LIST } },
  ]
}
