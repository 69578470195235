import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["RouterParametersController"], {
    initial: $setup.props.initial,
    to: $setup.parametersTo,
    from: $setup.parametersFrom,
    "onChange:internal": $setup.changeInternal,
    "onChange:external": $setup.changeExternal
  }, {
    default: _withCtx(({ parameters, changeParameters }) => [
      (_openBlock(), _createBlock(_resolveDynamicComponent($setup.props.controllerComponent), _mergeProps({
        parameters: parameters,
        immediate: true,
        "onUpdate:parameters": changeParameters
      }, _ctx.$attrs), {
        default: _withCtx((receivedParameters) => [
          _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(receivedParameters)))
        ]),
        _: 2
      }, 1040, ["parameters", "onUpdate:parameters"]))
    ]),
    _: 3
  }, 8, ["initial", "to", "from"]))
}