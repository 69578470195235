import { App } from 'vue'

import { length, numeric } from '@vee-validate/rules'
import { localize, setLocale } from '@vee-validate/i18n'

import { Form, Field, ErrorMessage, defineRule, configure } from './index'
import {
  url,
  required,
  address,
  eic,
  email,
  confirmed,
  postBackUrl,
  maxValue,
  minValue,
  min,
  decimal,
  max,
  maxArrLength,
  minArrLength,
  emailOrPhone,
  // realPhone,
  maskedPhone,
  iban,
} from './rules'

export default {
  async install(app: App<Element>): Promise<void> {
    app.component('VeeForm', Form)
    app.component('VeeField', Field)
    app.component('VeeError', ErrorMessage)
    defineRule('eic', eic.bind(app))
    defineRule('address', address.bind(app))
    defineRule('url', url.bind(app))
    defineRule('required', required.bind(app))
    defineRule('email', email.bind(app))
    defineRule('confirmed', confirmed.bind(app))
    defineRule('minValue', minValue.bind(app))
    defineRule('maxValue', maxValue.bind(app))
    defineRule('maxArrLength', maxArrLength.bind(app))
    defineRule('minArrLength', minArrLength.bind(app))
    defineRule('postBackUrl', postBackUrl.bind(app))
    defineRule('email_or_phone', emailOrPhone.bind(app))
    defineRule('length', length.bind(app))
    defineRule('numeric', numeric)
    defineRule('max', max)
    defineRule('min', min)
    defineRule('iban', iban)
    defineRule('decimal', decimal)
    // defineRule('realPhone', realPhone)
    defineRule('maskedPhone', maskedPhone)

    const { language: lang } = window
    await import(`@vee-validate/i18n/dist/locale/${lang}.json`)
      .then((dictionary) => {
        configure({
          generateMessage: localize({ [lang]: dictionary }) as any,
        })
        setLocale(lang)
      })
      .catch(() => {
        console.warn(`Vee-Validate: Locale - ${lang} was not found!`)
      })
  },
}
