import { defineAsyncComponent, App } from 'vue'

const Plus = defineAsyncComponent(() => import('./assets/svg/plus.svg'))
const Minus = defineAsyncComponent(() => import('./assets/svg/minus.svg'))
const Close = defineAsyncComponent(() => import('./assets/svg/close.svg'))
const UserSquare = defineAsyncComponent(
  () => import('./assets/svg/user-square.svg')
)
const Flash = defineAsyncComponent(() => import('./assets/svg/flash.svg'))
const DocumentDownload = defineAsyncComponent(
  () => import('./assets/svg/document-download.svg')
)
const Element = defineAsyncComponent(() => import('./assets/svg/element-3.svg'))
const DocumentText = defineAsyncComponent(
  () => import('./assets/svg/document-text.svg')
)
const Copyright = defineAsyncComponent(
  () => import('./assets/svg/copyright.svg')
)
const Task = defineAsyncComponent(() => import('./assets/svg/task.svg'))
const Notification = defineAsyncComponent(
  () => import('./assets/svg/notification.svg')
)
const MessageQuestion = defineAsyncComponent(
  () => import('./assets/svg/message-question.svg')
)
const Logout = defineAsyncComponent(() => import('./assets/svg/logout.svg'))
const ChevronLeft = defineAsyncComponent(
  () => import('./assets/svg/chevron-left.svg')
)
const ChevronRight = defineAsyncComponent(
  () => import('./assets/svg/chevron-right.svg')
)
const ChevronDown = defineAsyncComponent(
  () => import('./assets/svg/chevron-down.svg')
)
const ChevronUp = defineAsyncComponent(
  () => import('./assets/svg/chevron-up.svg')
)
const ArrowUp = defineAsyncComponent(() => import('./assets/svg/arrow-up.svg'))
const ArrowDown = defineAsyncComponent(
  () => import('./assets/svg/arrow-down.svg')
)
const ArrowLeft = defineAsyncComponent(
  () => import('./assets/svg/arrow-left.svg')
)
const Calendar = defineAsyncComponent(() => import('./assets/svg/calendar.svg'))
const ProfileCircle = defineAsyncComponent(
  () => import('./assets/svg/profile-circle.svg')
)
const Check = defineAsyncComponent(() => import('./assets/svg/check.svg'))
const Settings = defineAsyncComponent(() => import('./assets/svg/settings.svg'))
const FileMinus = defineAsyncComponent(
  () => import('./assets/svg/file-minus.svg')
)
const KeyRound = defineAsyncComponent(
  () => import('./assets/svg/key-round.svg')
)
const EditLine = defineAsyncComponent(
  () => import('./assets/svg/edit-line.svg')
)
const PlusSquare = defineAsyncComponent(
  () => import('./assets/svg/plus-square.svg')
)
const CloseCircle = defineAsyncComponent(
  () => import('./assets/svg/close-circle.svg')
)
const UnfoldMoreHorizontal = defineAsyncComponent(
  () => import('./assets/svg/unfold-more-horizontal.svg')
)
const DocumentSign = defineAsyncComponent(
  () => import('./assets/svg/document-sign.svg')
)
const Save = defineAsyncComponent(() => import('./assets/svg/save.svg'))
const CheckCircle = defineAsyncComponent(
  () => import('./assets/svg/check-circle.svg')
)
const ListFilter = defineAsyncComponent(
  () => import('./assets/svg/list-filter.svg')
)
const Search = defineAsyncComponent(() => import('./assets/svg/search.svg'))
const Wallet = defineAsyncComponent(() => import('./assets/svg/wallet.svg'))
const Info = defineAsyncComponent(() => import('./assets/svg/info.svg'))
const Login = defineAsyncComponent(() => import('./assets/svg/login.svg'))
const BookmarkPlus = defineAsyncComponent(() => import('./assets/svg/bookmark-plus.svg'))
const Scale = defineAsyncComponent(() => import('./assets/svg/scale.svg'))

const install = (app: App<Element>, prefix = 'I'): void => {
  app
    .component(`${prefix}Plus`, Plus)
    .component(`${prefix}Minus`, Minus)
    .component(`${prefix}Close`, Close)
    .component(`${prefix}UserSquare`, UserSquare)
    .component(`${prefix}Flash`, Flash)
    .component(`${prefix}DocumentDownload`, DocumentDownload)
    .component(`${prefix}Element`, Element)
    .component(`${prefix}DocumentText`, DocumentText)
    .component(`${prefix}Copyright`, Copyright)
    .component(`${prefix}Task`, Task)
    .component(`${prefix}Notification`, Notification)
    .component(`${prefix}MessageQuestion`, MessageQuestion)
    .component(`${prefix}Logout`, Logout)
    .component(`${prefix}ChevronLeft`, ChevronLeft)
    .component(`${prefix}ChevronRight`, ChevronRight)
    .component(`${prefix}ArrowUp`, ArrowUp)
    .component(`${prefix}ArrowDown`, ArrowDown)
    .component(`${prefix}ArrowLeft`, ArrowLeft)
    .component(`${prefix}Calendar`, Calendar)
    .component(`${prefix}ProfileCircle`, ProfileCircle)
    .component(`${prefix}Check`, Check)
    .component(`${prefix}Settings`, Settings)
    .component(`${prefix}FileMinus`, FileMinus)
    .component(`${prefix}KeyRound`, KeyRound)
    .component(`${prefix}EditLine`, EditLine)
    .component(`${prefix}PlusSquare`, PlusSquare)
    .component(`${prefix}ChevronDown`, ChevronDown)
    .component(`${prefix}ChevronUp`, ChevronUp)
    .component(`${prefix}CloseCircle`, CloseCircle)
    .component(`${prefix}UnfoldMoreHorizontal`, UnfoldMoreHorizontal)
    .component(`${prefix}DocumentSign`, DocumentSign)
    .component(`${prefix}Save`, Save)
    .component(`${prefix}CheckCircle`, CheckCircle)
    .component(`${prefix}ListFilter`, ListFilter)
    .component(`${prefix}Search`, Search)
    .component(`${prefix}Wallet`, Wallet)
    .component(`${prefix}Info`, Info)
    .component(`${prefix}Login`, Login)
    .component(`${prefix}BookmarkPlus`, BookmarkPlus)
    .component(`${prefix}Scale`, Scale)
}

export default { install }
