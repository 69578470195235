import { RouteRecordRaw } from 'vue-router'
import { route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'

const REQUESTS_PREFIX = 'cabinet:requests'
const p = (name: string) => REQUESTS_PREFIX + ':' + name

export const REQUESTS_COMMUNICATIONS = p('communications')
export const REQUESTS_OWN_COMMUNICATIONS = p('own-communications')
export const REQUESTS_MISMATCH_REPORTS = p('mismatch_reports')
export const REQUESTS_OWN_MISMATCH_REPORTS = p('onw-mismatch_reports')
export const REQUESTS_WITHDRAWAL = p('withdrawal')
export const REQUESTS_OWN_WITHDRAWAL = p('onw-withdrawal')
export const REQUESTS_REPLENISHMENT = p('replenishment')
export const REQUESTS_OWN_REPLENISHMENT = p('onw-replenishment')
export const REQUESTS_COMPANY_CHANGE = p('company_change')
export const REQUESTS_OWN_COMPANY_CHANGE = p('onw-company_change')
export const REQUESTS_COMPANY_VERIFICATION = p('company_verification')
export const REQUESTS_OWN_COMPANY_VERIFICATION = p('onw-company_verification')

const CommunicationsList = () => import('./views/communications/List.vue')
const OwnCommunicationsList = () => import('./views/communications/OwnList.vue')
const MismatchReportsList = () => import('./views/mismatch-reports/List.vue')
const OwnMismatchReportsList = () => import('./views/mismatch-reports/OwnList.vue')
const WithdrawalList = () => import('./views/withdrawal/List.vue')
const OwnWithdrawalList = () => import('./views/withdrawal/OwnList.vue')
const ReplenishmentList = () => import('./views/replenishment/List.vue')
const OwnReplenishmentList = () => import('./views/replenishment/OwnList.vue')
const CompanyChangeList = () => import('./views/company-change/List.vue')
const OwnCompanyChangeList = () => import('./views/company-change/OwnList.vue')
const CompanyVerificationList = () => import('./views/company-verification/List.vue')
const OwnCompanyVerificationList = () => import('./views/company-verification/OwnList.vue')

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route('communication/', CommunicationsList, REQUESTS_COMMUNICATIONS, {
      meta: {
        title: $t('Заявки з форми зворотного зв\'язку'),
      }
    }),
    route('own-communication/', OwnCommunicationsList, REQUESTS_OWN_COMMUNICATIONS, {
      meta: {
        title: $t('Мої заявки з форми зворотного зв\'язку'),
      }
    }),
    route('mismatch-reports/', MismatchReportsList, REQUESTS_MISMATCH_REPORTS, {
      meta: {
        title: $t('Заявки на невідповідність даних у звіті'),
      }
    }),
    route('own-mismatch-reports/', OwnMismatchReportsList, REQUESTS_OWN_MISMATCH_REPORTS, {
      meta: {
        title: $t('Мої заявки на невідповідність даних у звіті'),
      }
    }),
    route('withdrawal/', WithdrawalList, REQUESTS_WITHDRAWAL, {
      meta: {
        title: $t('Заявки на вивід грошових коштів'),
      }
    }),
    route('own-withdrawal/', OwnWithdrawalList, REQUESTS_OWN_WITHDRAWAL, {
      meta: {
        title: $t('Мої заявки на вивід грошових коштів'),
      }
    }),
    route('replenishment/', ReplenishmentList, REQUESTS_REPLENISHMENT, {
      meta: {
        title: $t('Заявки на поповнення'),
      }
    }),
    route('own-replenishment/', OwnReplenishmentList, REQUESTS_OWN_REPLENISHMENT, {
      meta: {
        title: $t('Мої заявки на поповнення'),
      }
    }),
    route('company-change/', CompanyChangeList, REQUESTS_COMPANY_CHANGE, {
      meta: {
        title: $t('Заявки на змінення даних компанії'),
      }
    }),
    route('own-company-change/', OwnCompanyChangeList, REQUESTS_OWN_COMPANY_CHANGE, {
      meta: {
        title: $t('Мої заявки на змінення даних компанії'),
      }
    }),
    route('company-verification/', CompanyVerificationList, REQUESTS_COMPANY_VERIFICATION, {
      meta: {
        title: $t('Усі заявки на верифікацію'),
      }
    }),
    route('own-company-verification/', OwnCompanyVerificationList, REQUESTS_OWN_COMPANY_VERIFICATION, {
      meta: {
        title: $t('Мої зсі зиявки на верифікацію'),
      }
    }),
    { path: '', redirect: { name: REQUESTS_COMMUNICATIONS } },
  ]
}
