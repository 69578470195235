import type { RouteRecordRaw } from 'vue-router'
import { i18n } from '@/i18n'
import { route } from '@/router/utils'
import { receiveRoute } from '@m/cabinet/components/views/utils'
import type { Options } from '@/router'
import { companyApi } from '@/services/company.service'

const Receive = () => import('@/modules/cabinet/components/views/Receive.vue')

const PROFILE_PREFIX = 'cabinet:companies'
const p = (name: string) => PROFILE_PREFIX + ':' + name

export const COMPANIES_LIST = p('list')
export const COMPANIES_EDIT = p('edit')

const Edit = () => import('./views/Edit.vue')
const List = () => import('./views/List.vue')

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route('list/', List, COMPANIES_LIST, {
      meta: {
        title: () => $t('Усі компанії'),
      },
    }),

    receiveRoute(
      companyApi.retrieve,
      [
        route('edit/', Edit, COMPANIES_EDIT, {
          meta: {
            title: () => $t('Редагування компанії'),
          },
        }),
        { path: '', redirect: { name: COMPANIES_EDIT } },
      ],
    ),
    { path: '', redirect: { name: COMPANIES_LIST } },
  ]
}
