import {
  evolve,
  map,
  append,
  pluck,
  reject,
  isNil,
  uniq,
  toPairs,
  chain,
  reduce,
  pipe,
  filter,
  not,
} from 'ramda'
import { parametersMiddleware } from '@aspectus/resource'

type Transformer<T> = (s: Record<string, any>) => T

const isUndefined = (value: any): value is undefined =>
  typeof value === 'undefined'
const isEmpty = (value: any): value is undefined | null =>
  isUndefined(value) || value === null

export function keyedTransformer<T>(
  transformer: Transformer<T>,
  from: string,
  to: string = from,
  transformOnUndefined = false,
  dflt: any = undefined
): Record<string, any> {
  return function fn(source: Record<string, any>, result: Record<string, any>) {
    const data =
      source && 'undefined' !== typeof source[from] && null !== source[from]
        ? source[from]
        : dflt

    if (!transformOnUndefined && 'undefined' === typeof data) {
      return result
    }

    return {
      ...result,
      [to]: transformer(data),
    }
  }
}

const k = keyedTransformer

export function symmetricalKeyTransformers<F, T>(
  fromHandler: Transformer<F>,
  toHandler: Transformer<T>,
  fromKey: string,
  toKey: string = fromKey
): [Record<string, any>, Record<string, any>] {
  return [
    keyedTransformer(fromHandler, fromKey, toKey),
    keyedTransformer(toHandler, toKey, fromKey),
  ]
}

export function sameTransformer<T>(data: T): T {
  return data
}
export function simpleTransformer(data: unknown): any {
  return pipe(sameTransformer, checkEmpty)
}
export function toNumber<T>(data: T): number {
  return Number(data)
}

export function composeTransformers(
  ...transformers: any[]
): (source: Record<string, any>) => Record<string, any> {
  const prepared = transformers.reverse()

  return function fn(source: Record<string, any>) {
    return prepared.reduce((acc, transform) => transform(source, acc), {})
  }
}

export const normalizeSelectValue = (value: any | any[]): any[] =>
  Array.isArray(value) || 'undefined' === typeof value || null === value
    ? value || []
    : [value]

export const checkEmpty = <T>(value: T): T | undefined =>
  isEmpty(value) ? undefined : value
// eslint-disable-next-line no-nested-ternary
const ar = <T>(value: T | T[]): T | T[] =>
  Array.isArray(value) ? value : [value]
// const selectTo = curry((filter, id) => filter.props.items.find(({ id }) => id === id));
export const selectFrom = ({
  id,
}: Partial<{ id: string | number }>): string | number => {
  return id || null
}
export const selectSlugFrom = ({
  slug,
}: Partial<{ slug: string | number }> = {}): string | number => slug
export const multiselectFrom = pipe(
  ar,
  map(selectFrom),
  filter(pipe(isEmpty, not))
)
export const multiselectSlugFrom = pipe(
  ar,
  map(selectSlugFrom),
  filter(pipe(isEmpty, not))
)

export const bmSlugFrom = (
  el: Partial<{ slug: string | number }> = {}
): string | number | undefined => {
  return el?.slug || undefined
}

const brandModelFrom = evolve({ brand: bmSlugFrom, model: bmSlugFrom })
// const finishBrandModelFrom = (acc, [key, val]) => {
const finishBrandModelFrom = (acc, value) => {
  const [key, val] = value
  if (val) {
    acc[key] = append(val, acc[key] || [])
    acc[key] = uniq(acc[key] || [])
  }
  return acc
}

const brandModelTransform = (data) => ({
  brand: pipe(pluck('brand'), reject(isNil), pluck('slug'), uniq)(data || []),
  model: pipe(pluck('model'), reject(isNil), pluck('slug'), uniq)(data),
})
// export const brandsModelsFrom = pipe(ar, brandModelTransform)
export const brandsModelsFrom = pipe(
  ar,
  map(evolve({ brand: bmSlugFrom, model: bmSlugFrom }))
)

const selectValueFrom = ({ value }: Partial<{ value: string | number }>) =>
  value
const multiselectValueFrom = pipe(
  ar,
  map(selectValueFrom),
  filter(pipe(isEmpty, not))
)
const dateFrom = (date: string) => new Date(date).toISOString()
const dateOnlyFrom = (date: string) => {
  const dateTransform = new Date(date)
  return `${dateTransform.getFullYear()}-${
    ('0' + String(dateTransform.getMonth() + 1)).slice(-2)
  }-${('0' + String(dateTransform.getDate())).slice(-2)}`
}
const yearFrom = (date: string) => {
  const dateTransform = new Date(date)
  return date ? dateTransform.getFullYear() : undefined
}
const dateTo = (date: string) => (date ? new Date(date) : null)
const dateRangeFrom = evolve({ min: dateFrom, max: dateFrom })
export const dateOnlyRangeFrom = evolve({
  min: dateOnlyFrom,
  max: dateOnlyFrom,
})
export const rangeFrom = evolve({ min: sameTransformer, max: sameTransformer })
export const yearRangeFrom = evolve({ min: yearFrom, max: yearFrom })
const combinedInputFrom = map(
  composeTransformers(
    k<any>(selectFrom, 'element', 'id'),
    k<any>(sameTransformer, 'search')
  )
)

export const simplifiers = {
  toNumber: (key: string): Record<string, any> => k(toNumber, key),
  same: (key: string, toKey?: string): Record<string, any> =>
    k(sameTransformer, key, toKey),
  simple: (key: string, toKey?: string): Record<string, any> =>
    k(pipe(sameTransformer, checkEmpty), key, toKey),
  search: (key: string): Record<string, any> =>
    k(pipe(sameTransformer, checkEmpty), key),
  select: (key: string, toKey?: string): Record<string, any> =>
    k(pipe(selectFrom, checkEmpty), key, toKey),
  selectValue: (key: string): Record<string, any> =>
    k(pipe(selectValueFrom, checkEmpty), key),
  selectSimple: (key: string): Record<string, any> => k(pipe(selectFrom), key),
  multiselect: (key: string, toKey?: string): Record<string, any> =>
    k(pipe(multiselectFrom, checkEmpty), key, toKey),
  multiselectValue: (key: string): Record<string, any> =>
    k(pipe(multiselectValueFrom, checkEmpty), key),
  multiselectSimple: (key: string, toKey?: string): Record<string, any> =>
    k(pipe(multiselectFrom), key, toKey),
  range: (key: string): Record<string, any> =>
    k(pipe(sameTransformer, checkEmpty), key),
  dateTo: (key: string): Record<string, any> => k(pipe(dateTo), key),
  dateRange: (key: string): Record<string, any> =>
    k(pipe(dateRangeFrom, checkEmpty), key),
  dateOnlyRangeFrom: (key: string): Record<string, any> =>
    k(pipe(dateOnlyRangeFrom, checkEmpty), key),
  onlyDate: (key: string): Record<string, any> =>
    k(pipe(dateOnlyFrom, checkEmpty), key),
  autocompleteMultiselect: (key: string): Record<string, any> =>
    k(pipe(multiselectFrom, checkEmpty), key),
  combinedInput: (key: string): Record<string, any> =>
    k(pipe(combinedInputFrom, checkEmpty), key),
}

export const filterAPIMiddleware = (
  transformer: (d: Record<string, any>) => Record<string, any>
): Record<string, any> =>
  parametersMiddleware((parameters: Record<string, any>) => ({
    ...parameters,
    filters: JSON.stringify(transformer(parameters.filters)),
  }))
export const parametersAPIMiddleware = (
  transformer: (d: Record<string, any>) => Record<string, any>
): Record<string, any> =>
  parametersMiddleware(
    (parameters: Record<string, any>): { [key: string]: any } => ({
      ...parameters,
      ...transformer(parameters.filters),
    })
  )
