import { defineComponent, ref, watch, SetupContext } from 'vue'
import { omit, pick } from 'ramda'
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router'

const same = (x: any): any => x
const pathOmitter = omit(['path'])
const routerPicker = pick(['query', 'path', 'params', 'name'])
const routerOmitter = (value: any) =>
  'name' in value ? pathOmitter(value) : value
const INTERNAL_KEY = '__internal__'

const CHANGE_EVENT = 'change'
const CHANGE_INITIAL_EVENT = 'change:initial'
const CHANGE_INTERNAL_EVENT = 'change:internal'
const CHANGE_EXTERNAL_EVENT = 'change:external'

export default defineComponent({
  name: 'RouterParametersController',
  props: {
    initial: {
      type: Object,
      default: () => ({}),
    },
    from: {
      type: Function,
      default: same,
    },
    to: {
      type: Function,
      default: same,
    },
  },

  emits: [
    CHANGE_EVENT,
    CHANGE_INITIAL_EVENT,
    CHANGE_INTERNAL_EVENT,
    CHANGE_EXTERNAL_EVENT,
  ],
  setup(props, { slots, emit }) {
    const parameters = ref<Record<string, unknown>>(props.initial || {})
    const hadChanged = ref<boolean>(false)
    const route = useRoute()
    const router = useRouter()

    watch(
      [() => route.params, () => route.query],
      ([params, query], [oldParams, oldQuery]) => {
          parameters.value = props.from(Object.assign( // eslint-disable-line prefer-object-spread
            {}, props.initial, params, query, 
          ));

        const event = { parameters: parameters.value };

        emit(CHANGE_EVENT, event);

        if (params && params[INTERNAL_KEY]) {
          emit(CHANGE_INTERNAL_EVENT, event);

          return;
        }

        if (oldParams && oldQuery) {
          emit(CHANGE_EXTERNAL_EVENT, event);
        }

        if (!oldParams || !oldQuery || !hadChanged.value) {
          emit(CHANGE_INITIAL_EVENT, event);
          hadChanged.value = true;
        }
      }, { immediate: true }
    )
    // watch(
    //   route,
    //   (value, oldValue) => {
    //     parameters.value = props.from(
    //       Object.assign(
    //         // eslint-disable-line prefer-object-spread
    //         {},
    //         props.initial,
    //         value.params,
    //         value.query
    //       )
    //     )

    //     const event = { parameters: parameters.value }

    //     emit(CHANGE_EVENT, event)

    //     if (value.params && value.params[INTERNAL_KEY]) {
    //       emit(CHANGE_INTERNAL_EVENT, event)

    //       return
    //     }

    //     if (oldValue) {
    //       emit(CHANGE_EXTERNAL_EVENT, event)
    //     }

    //     if (!oldValue || !hadChanged) {
    //       emit(CHANGE_INITIAL_EVENT, event)
    //       hadChanged.value = true
    //     }
    //   },
    //   { immediate: true }
    // )
    // onBeforeRouteUpdate((to, from) => {
    //   parameters.value = props.from(Object.assign( // eslint-disable-line prefer-object-spread
    //       {}, props.initial, to.params, to.query
    //     ));

    //     const event = { parameters: parameters.value };

    //     emit(CHANGE_EVENT, event);

    //     if (to.params[INTERNAL_KEY]) {
    //       emit(CHANGE_INTERNAL_EVENT, event);

    //       return;
    //     }

    //     if (from) {
    //       emit(CHANGE_EXTERNAL_EVENT, event);

    //     }

    //     if (!from || !hadChanged) {
    //       emit(CHANGE_INITIAL_EVENT, event);
    //       hadChanged.value = true;
    //     }
    // })

    function updateUrl(parameters: any) {
      const parameterKeys = Object.keys(route.params)
      const paramsPicker = pick(parameterKeys)
      const queryGetter = route.name ? omit(parameterKeys) : same
      const query = props.to(parameters)

      router
        .push(
          routerOmitter(
            routerPicker(
              Object.assign(
                // eslint-disable-line prefer-object-spread
                {},
                route,
                {
                  query: queryGetter(query),
                  params: Object.assign(
                    // eslint-disable-line prefer-object-spread
                    {},
                    route.params,
                    paramsPicker(query),
                    {
                      [INTERNAL_KEY]: true,
                    }
                  ),
                }
              )
            )
          )
        )
        .catch(() => {
          console.warn('same url')
        }) // For the same URL vue-router throws here.
    }

    function changeParameters(value: any) {
      updateUrl(value)
    }

    return () =>
      slots.default?.({
        parameters: parameters.value,
        changeParameters,
        updateUrl,
      })
  },
})
