function scrollHandler(this: HTMLElement) {
  const scrollOffset = 100
  if (window.scrollY > scrollOffset) {
    this.classList.add('is-active')
  } else {
    this.classList.remove('is-active')
  }
}
export const scrollToTop = {
  mounted: (targetElement: HTMLElement): void => {
    window.addEventListener('scroll', scrollHandler.bind(targetElement), {
      passive: true,
    })
  },
  unmounted(): void {
    window.removeEventListener('scroll', scrollHandler)
  },
}
