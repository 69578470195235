import type { RouteRecordRaw } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'
import { ordersApi } from '@/services/orders.service'
import { receiveRoute } from '@m/cabinet/components/views/utils'
// import { receiveRoute } from '@m/cabinet/components/views/utils'
// import { communicationApi } from '@/services/communication.service'

const PREFIX = 'cabinet:'
const OFFERS_PREFIX = PREFIX + 'offers:'
const pOffers = (name: string) => OFFERS_PREFIX + ':' + name
const APPLICATIONS_PREFIX = PREFIX + 'applications:'
const pApplications = (name: string) => APPLICATIONS_PREFIX + ':' + name

export const OFFERS_CREATE = pOffers('create')
export const OFFERS_LIST = pOffers('list')
export const OFFERS_OWN_LIST = pOffers('own_list')
export const OFFERS_OWN_EDIT = pOffers('own_edit')

export const APPLICATIONS_CREATE = pApplications('create')
export const APPLICATIONS_LIST = pApplications('list')
export const APPLICATIONS_OWN_LIST = pApplications('own_list')
export const APPLICATIONS_OWN_EDIT = pApplications('own_edit')

const CreateOffer = () => import('./views/Offers/Create.vue')
const EditOffers = () => import('./views/Offers/Edit.vue')
const ListOffers = () => import('./views/Offers/List/index.vue')
const OwnListOffers = () => import('./views/Offers/List/OwnList.vue')


const CreateApplications = () => import('./views/Applications/Create.vue')
const EditApplications = () => import('./views/Applications/Edit.vue')
const ListApplications = () => import('./views/Applications/List/index.vue')
const OwnListApplications = () => import('./views/Applications/List/OwnList.vue')

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    prefixRoutes('applications/', [
      route('list/', ListApplications, APPLICATIONS_LIST, {
        meta: {
          title: () => $t('Заявка купівлі електроенергії'),
        },
      }),
      route('own-list/', OwnListApplications, APPLICATIONS_OWN_LIST, {
        meta: {
          title: () => $t('Мої заявки'),
        },
      }),
      // route('templates/', List, APPLICATIONS_LIST, {
      //   meta: {
      //     title: () => $t('Шаблони пропозицій'),
      //   },
      // }),

      receiveRoute(
        ordersApi.own.retrieve,
        [
          route('edit/', EditApplications, APPLICATIONS_OWN_EDIT, {
            meta: {
              title: () => $t('Редагування заявки'),
            },
          }),
          { path: '', redirect: { name: APPLICATIONS_OWN_EDIT } },
        ],
      ),
      route('create/', CreateApplications, APPLICATIONS_CREATE, {
        meta: {
          title: () => $t('Створення заявки'),
          fallbackRouteName: APPLICATIONS_OWN_LIST,
        },
      }),
      { path: '', redirect: { name: APPLICATIONS_LIST } },
    ]),
    prefixRoutes('offers/', [
      route('list/', ListOffers, OFFERS_LIST, {
        meta: {
          title: () => $t('Пропозиції на продаж електроенергії'),
        },
      }),
      route('own-list/', OwnListOffers, OFFERS_OWN_LIST, {
        meta: {
          title: () => $t('Мої пропозиції'),
        },
      }),
      // route('templates/', List, OFFERS_LIST, {
      //   meta: {
      //     title: () => $t('Шаблони пропозицій'),
      //   },
      // }),
      route('create/', CreateOffer, OFFERS_CREATE, {
        meta: {
          title: () => $t('Створення пропозиції'),
          fallbackRouteName: OFFERS_OWN_LIST,
        },
      }),

      receiveRoute(
        ordersApi.own.retrieve,
        [
          route('edit/', EditOffers, OFFERS_OWN_EDIT, {
            meta: {
              title: () => $t('Редагування пропозиції'),
            },
          }),
          { path: '', redirect: { name: OFFERS_OWN_EDIT } },
        ],
      ),
      { path: '', redirect: { name: OFFERS_LIST } },

    ]),
    { path: '', redirect: { name: OFFERS_LIST } },
  ]
}
