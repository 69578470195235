import type { App } from 'vue'
import { tableWrapper } from './table'
import { scroll } from './scroll'
import { scrollToTop } from './scrollToTop'

export default {
  install(app: App<Element>): void {
    app.directive('table-wrapper', tableWrapper)
    app.directive('scroll', scroll)
    app.directive('scrollToTop', scrollToTop)
  },
}
