import { type App, type DefineComponent, type Plugin } from 'vue'
import { RouteRecordRaw } from 'vue-router'
import { route, prefixRoutes } from '@router/utils'
import type { Options } from '@/router'

import { createRoutes as createProfileRoutes } from './modules/Profile/router'
import { createRoutes as createCompanyRoutes } from './modules/Company/router'
import { createRoutes as createCompaniesRoutes } from './modules/Companies/router'
import { createRoutes as createReportsRoutes } from './modules/Reports/router'
import { createRoutes as createDocumentsRoutes } from './modules/Documents/router'
import { createRoutes as createOrdersRoutes } from './modules/Orders/router'
import { createRoutes as createTemplatesRoutes } from './modules/Templates/router'
import { createRoutes as createPricesRoutes } from './modules/Prices/router'
// import { createRoutes as createApplicationsRoutes } from './modules/Applications/router'
import { createRoutes as createDealsRoutes } from './modules/Deals/router'
import { createRoutes as createNotificationsRoutes } from './modules/Notifications/router'
import { createRoutes as createRequestsRoutes } from './modules/Requests/router'

import { i18n } from '@/i18n'

import { PRICES_VIEW, COMPANY_VIEW } from './routes'
import { UserRoles } from '@/const'

const CabinetView = () => import('./views/CabinetView.vue')
const CabinetInstaller = () => import('./components')
const RouteNotPermitted = () => import('./views/RouteNotPermitted.vue')
const RouteNotFound = () => import('./views/RouteNotFound.vue')

function installer<T extends DefineComponent>(
  View: any,
  Installer: () => Promise<T>,
  app: App<Element>
): () => Promise<Awaited<T>> {
  return function (): Promise<Awaited<T>> {
    return Promise.all([View(), Installer()]).then(function (result) {
      const [ViewComponent, installer] = result
      app.use(installer?.default || installer?.install || installer)
      return ViewComponent
    })
  }
}

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { app } = options
  const { t: $t } = i18n.global
  return [
    route(
      '',
      installer<any>(
        CabinetView,
        CabinetInstaller as unknown as () => Promise<Plugin>,
        app
      ),
      'cabinet',
      {
        children: [
          {
            path: '',
            redirect: {
              name:
                window.u_rl === UserRoles.client ? COMPANY_VIEW : PRICES_VIEW,
            },
          },
          prefixRoutes('profile/', createProfileRoutes(options)),
          prefixRoutes('company/', createCompanyRoutes(options)),
          prefixRoutes('companies/', createCompaniesRoutes(options)),
          prefixRoutes('documents/', createDocumentsRoutes(options)),
          prefixRoutes('reports/', createReportsRoutes(options)),
          prefixRoutes('prices/', createPricesRoutes(options)),
          // prefixRoutes('application/', createApplicationsRoutes(options)),
          prefixRoutes('deals/', createDealsRoutes(options)),
          prefixRoutes('notifications/', createNotificationsRoutes(options)),
          prefixRoutes('requests/', createRequestsRoutes(options)),
          prefixRoutes('templates/', createTemplatesRoutes(options)),
          prefixRoutes('', createOrdersRoutes(options)),
          {
            path: '/:pathMatch(.*)*',
            name: 'cabinet:not-found',
            component: RouteNotFound,
            meta: {
              title: () => $t('404'),
            },
          },
          {
            path: '/:pathMatch(.*)*',
            name: 'cabinet:no-access',
            component: RouteNotPermitted,
            meta: {
              title: () => $t('403'),
            },
          },
        ],
        redirect: {
          name: window.u_rl === UserRoles.client ? COMPANY_VIEW : PRICES_VIEW,
        },
      }
    ),
  ]
}
