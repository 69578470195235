import type { RouteRecordRaw } from 'vue-router'
import { route } from '@/router/utils'
import type { Options } from '@/router'
import { receiveRoute } from '@m/cabinet/components/views/utils'
import { dealsApi } from '@/services/deals.service'
import { i18n } from '@/i18n'
// import { receiveRoute } from '@m/cabinet/components/views/utils'
// import { communicationApi } from '@/services/communication.service'

const PROFILE_PREFIX = 'cabinet:deals'
const p = (name: string) => PROFILE_PREFIX + ':' + name

export const DEALS_VIEW = p('view')
export const DEALS_LIST = p('list')
export const DEALS_OWN_LIST = p('own-list')
export const DEALS_OWN_VIEW = p('own-view')

const List = () => import('./views/List/index.vue')
const OwnList = () => import('./views/OwnList/index.vue')
const View = () => import('./views/View.vue')
const OwnView = () => import('./views/OwnView.vue')

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route('list/', List, DEALS_LIST, {
      meta: {
        title: () => $t('Усі угоди'),
      },
    }),
    route('own-list/', OwnList, DEALS_OWN_LIST, {
      meta: {
        title: () => $t('Мої угоди'),
      },
    }),
    receiveRoute(dealsApi.retrieve, [
      route('view/', View, DEALS_VIEW, {
        meta: {
          title: () => $t('Угода'),
          fallbackRouteName: DEALS_LIST,
        },
      }),
      { path: '', redirect: { name: DEALS_VIEW } },
    ]),
    receiveRoute(dealsApi.own.retrieve, [
      route('own-view/', OwnView, DEALS_OWN_VIEW, {
        meta: {
          title: () => $t('Угода'),
          fallbackRouteName: DEALS_OWN_LIST,
        },
      }),
      { path: '', redirect: { name: DEALS_VIEW } },
    ]),
    { path: '', redirect: { name: DEALS_LIST } },
  ]
}
