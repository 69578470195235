import { r, createSender } from '@/plugins/resource/resource'
import { queryPostfix as q } from '@/plugins/resource/api'

const MODEL = 'deals'
const OWN_MODEL = MODEL + '/own'
const AUXILIARY_MODEL = MODEL + '/auxiliary'

export const dealsApi = {
  calculateVat: r('/calculate/vat/', MODEL),
  list: r(q('/list/', ['filters']), MODEL),
  filtersList: r('/list/filters/', MODEL),
  retrieve: r('{/id}/retrieve/', MODEL),
  actions: {
    setStatus: r('/actions/set-status/', MODEL, createSender),
    addComment: r('/actions/add-comment/', MODEL, createSender),
    pay: r('/actions/pay/', MODEL, createSender),
    withdrawalFine: r('/actions/withdrawal-fine/', MODEL, createSender),
  },

  own: {
    list: r(q('/list/', ['filters']), OWN_MODEL),
    filtersList: r('/list/filters/', OWN_MODEL),
    retrieve: r('{/id}/retrieve/', OWN_MODEL),
    actions: {
      addComment: r('/actions/add-comment/', OWN_MODEL, createSender),
      pay: r('/actions/pay/', OWN_MODEL, createSender),
    },
  },

  auxiliary: {
    statusesList: r('/statuses/list/', AUXILIARY_MODEL),
    statusesPossibleChange: r('/statuses/possible-changes/', AUXILIARY_MODEL),
    marketTypesList: r('/market-types/list/', AUXILIARY_MODEL),
    pricingTypesList: r('/pricing-types/list/', AUXILIARY_MODEL),
    matchTypesList: r('/match-types/list/', AUXILIARY_MODEL),
  },
}
