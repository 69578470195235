export function disableBeforeDate({
  date = new Date(),
  include = true,
}: {
  date?: Date
  include?: boolean
}): (d: number) => boolean {
  return (d: number) => d < new Date(date).setHours(include ? 0 : 24, 0, 0, 0)
}
export function disableBetweenDate({
  min = new Date(),
  max = new Date(),
  include = true,
}: { min?: Date; max?: Date; include?: boolean}): (d: number) => boolean {
  return (d: number) =>
    d < new Date(min).setHours(include ? 0 : 24, 0, 0, 0) ||
    d > new Date(max).setHours(include ? 0 : 24, 0, 0, 0)
}

export function disableAfterDate({
  date = new Date(),
  include = true,
}: {
  date?: Date
  include?: boolean
}): (d: number) => boolean {
  return (d: number) => d > new Date(date).setHours(include ? 0 : 24, 0, 0, 0)
}

export function getVideoId(url: string): {
  embedUrl: string
  previewUrl: string
} {
  // eslint-disable-next-line no-useless-escape
  const regExpYoutube =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  // eslint-disable-next-line no-useless-escape
  const regExpVimeo =
    /^.*(?:vimeo.com)\/(?:channels\/|channels\/\w+\/|groups\/[^/]*\/videos\/|album\/\d+\/video\/|video\/|)(\d+)(?:$|\/|\?)/
  const matchY = url.match(regExpYoutube)
  const matchV = url.match(regExpVimeo)
  const youtubeIdLength = 11
  let embedUrl
  let previewUrl
  if (matchY && matchY[2].length === youtubeIdLength) {
    const id = matchY[2]
    embedUrl = `https://www.youtube.com/embed/${id}?enablejsapi=1&modestbranding=1&rel=0&autoplay=1&mute=0&iv_load_policy=3`
    // this.embedUrl = `https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1"`;
    previewUrl = `https://img.youtube.com/vi/${id}/hq720.jpg`
    return { embedUrl, previewUrl }
  }
  if (matchV && matchV[1]) {
    const id = matchV[1]
    embedUrl = `https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0&autoplay=1`
    previewUrl = `https://vumbnail.com/${id}.jpg`
    return { embedUrl, previewUrl }
  }
  throw new Error(`error parsed link: ${url}`)
}

export const toArray = <T>(value: T | T[]): T | T[] =>
  Array.isArray(value) ? value : [value]
