import { App } from 'vue'

import Control from '@/components/controls'
// import Common from '@/components/common'
import Forms from '@/components/forms'
import General from '@/components/general'
import UiComponents from '@/components/ui'
import Public from '@/components/public'

const install = (app: App<Element>): void => {
  app
    .use(Control)
    .use(Forms)
    .use(UiComponents)
    .use(General)
    .use(Public)
    // .use(Common)
}

export default {
  install,
}
