import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createBlock as _createBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: $setup.blockClasses }, { 'aria-disabled': $props.disabled }), [
    _createElementVNode("span", {
      class: _normalizeClass([`${$props.blockName}__body`])
    }, [
      _renderSlot(_ctx.$slots, "prepend"),
      ($props.startIcon || _ctx.$slots.startIcon)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass([`${$props.blockName}__element`, `${$props.blockName}__element--attachment_prepend`])
          }, [
            _renderSlot(_ctx.$slots, "startIcon", {}, () => [
              _createVNode(_component_AppIcon, {
                name: $props.startIcon,
                size: $props.iconSize
              }, null, 8, ["name", "size"])
            ])
          ], 2))
        : _createCommentVNode("", true),
      ($props.mask)
        ? _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent($props.innerElement), _mergeProps({
            key: 1,
            class: [`${$props.blockName}__element`, `${$props.blockName}__element--primary`]
          }, 'phone-input' === $props.innerElement ? { ..._ctx.$attrs } : { value: $props.value, ..._ctx.$attrs }, {
            name: $props.name,
            type: $setup.inputType,
            readonly: $props.readonly,
            disabled: $props.disabled,
            id: $props.id,
            "data-maska": $props.mask
          }), _createSlots({ _: 2 }, [
            _renderList(_ctx.$slots, (_, name) => {
              return {
                name: name,
                fn: _withCtx((bound) => [
                  _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(bound || {})))
                ])
              }
            })
          ]), 1040, ["class", "name", "type", "readonly", "disabled", "id", "data-maska"])), [
            [$setup["vMaska"]]
          ])
        : (_openBlock(), _createBlock(_resolveDynamicComponent($props.innerElement), _mergeProps({
            key: 2,
            class: [`${$props.blockName}__element`, `${$props.blockName}__element--primary`]
          }, 'phone-input' === $props.innerElement ? { ..._ctx.$attrs } : { value: $props.value, ..._ctx.$attrs }, {
            name: $props.name,
            type: $setup.inputType,
            readonly: $props.readonly,
            disabled: $props.disabled,
            id: $props.id
          }), _createSlots({ _: 2 }, [
            _renderList(_ctx.$slots, (_, name) => {
              return {
                name: name,
                fn: _withCtx((bound) => [
                  _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(bound || {})))
                ])
              }
            })
          ]), 1040, ["class", "name", "type", "readonly", "disabled", "id"])),
      _renderSlot(_ctx.$slots, "password-toggle", {}, () => [
        ($setup.isPasswordField)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(["change-type", [`${$props.blockName}__element`, `${$props.blockName}__element--attachment_append`]]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.toggleType()))
            }, _toDisplayString($setup.inputType === $setup.p ? _ctx.$t('Показати') : _ctx.$t('Приховати')), 3))
          : _createCommentVNode("", true)
      ]),
      ($props.endIcon || _ctx.$slots.endIcon)
        ? (_openBlock(), _createElementBlock("span", {
            key: 3,
            class: _normalizeClass([`${$props.blockName}__element`, `${$props.blockName}__element--attachment_append`])
          }, [
            _renderSlot(_ctx.$slots, "endIcon", {}, () => [
              _createVNode(_component_AppIcon, {
                name: $props.endIcon,
                size: $props.iconSize
              }, null, 8, ["name", "size"])
            ])
          ], 2))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "append")
    ], 2),
    _renderSlot(_ctx.$slots, "slider")
  ], 16))
}