import { unref, computed, ComputedRef } from 'vue'
import type { Ref } from 'vue'

export type Key = number | string
export type FilterDefinition = any
export type FilterAvailabilityDefinition = any
export type KeyGetter = (a: any) => Key
export type ApplyFunction = (val: any) => any
export type WidgetBinderProps<T = any> = {
  key: Key
  type: Key
  component: any
  filter: FilterDefinition
  config: any
  modelValue: T,
  onUpdate: ApplyFunction
  onChange: ApplyFunction
  onCommit: () => void
}
export type WidgetBinder = (props: WidgetBinderProps) => {props?: any, children?: any}
export type WidgetConfig = {
  component: any
  config?: any
  binder?: WidgetBinder
}
export type WidgetsMap = Record<Key, WidgetConfig>
export type GeneratorConfig = {
  widgetsMap: Ref<WidgetsMap> | WidgetsMap
  filters: Ref<FilterDefinition[]>,
  onApply?: Ref<ApplyFunction> | ApplyFunction
  keyGetter?: Ref<KeyGetter> | KeyGetter
  typeGetter?: Ref<KeyGetter> | KeyGetter
  only?: Ref<Key[]> | Key[]
  omit?: Ref<Key[]> | Key[]
}
export type GeneratorNode = {
  component: any
  props: unknown
  children?: any
}

export const defaultKeyGetter = (a: { id: Key }): Key => a.id as Key
export const defaultTypeGetter = (a: { type: Key}): Key => a.type as Key
export const defaultBinder: WidgetBinder = ({ onChange, modelValue, onUpdate, ...rest }) => ({ props: {
  ...rest, modelValue, 'onUpdate:modelValue': onUpdate, 'onChange:modelValue': onChange,
} })

function keyReducer(acc: any, keyed: any) {
  acc[keyed.key] = keyed

  return acc
}

type FilterValue = { key: Key, type: Key, item: FilterDefinition }

export function useGenerator<T = unknown>(
  config: GeneratorConfig,
  value: Ref<T>,
): {
  keyGetter: ComputedRef<KeyGetter>
  typeGetter: ComputedRef<KeyGetter>
  value: Ref<T>
  nodes: ComputedRef<Record<Key, GeneratorNode>>
} {
  const keyGetter = computed(() => unref(config.keyGetter) || defaultKeyGetter)
  const typeGetter = computed(() => unref(config.typeGetter) || defaultTypeGetter)
  const filtersMap: Ref<Record<Key, FilterValue>> = computed(() => {
    return (unref(config.filters) || [])
      .map(item => ({
        key: keyGetter.value(item),
        type: typeGetter.value(item),
        item,
      }))
      .filter(filter => filter.type in unref(config.widgetsMap))
      .filter(filter => {
        const only = unref(config.only)

        if (typeof only === 'undefined' || only.length === 0) return true

        return only.includes(filter.key)
      })
      .filter(filter => !(unref(config.omit) || []).includes(filter.key))
      .reduce(keyReducer, {})
    }
  )
  const nodes = computed(() => {
    const result: Record<Key, GeneratorNode> = {}
    const v = unref(value) as any

    Object.values(filtersMap.value).forEach(({ key, type, item }) => {
      const {
        component,
        binder = defaultBinder,
        config: cfg,
      } = unref(config.widgetsMap)[type]
      const onChange = (val: any): T => {
        value.value = { ...unref(value), [key]: val } as unknown as T
        return value.value
      }
      const onCommit = () => {
        const apply = unref(config.onApply)

        if (apply) {
          apply(value.value)
        }
      }
      const { props, children } = binder({
        key, type, component,
        filter: item,
        config: cfg,
        modelValue: v[key],
        onChange,
        onCommit,
        onUpdate: (val: any) => {
          const r = onChange(val)
          onCommit()
          return r
        }
      })

      result[key] = { component, props, children }
    })

    return result
  })

  return { keyGetter, typeGetter, value, nodes }
}

export type Generator = ReturnType<typeof useGenerator>
