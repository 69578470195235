import { defineStore } from 'pinia'
import { ref, computed, Ref } from 'vue'
import { profileApi } from '@services/profile.service'
import { User as UserClass } from './user/user'

// declare global {
//   interface Window {
//     isAuthenticated: string | number
//   }
// }

export const useAuthStore = defineStore('authStore', () => {
  const loading: Ref<boolean> = ref(false)
  const user = ref(new UserClass({}, false))

  const isAuthenticated = computed(() => user.value.isAuthenticated())
  const isDetermined = computed(() => user.value.determined)

  function setUser(newUser: UserClass): void {
    user.value = newUser
  }
  function updateUser(data: { [key: string]: unknown }): void {
    user.value = new UserClass({ ...user.value.info, ...data })
  }
  function setLoading(value: boolean): void {
    loading.value = value
  }
  function initialReceive() {
    if (user.value.determined) {
      return null
    }
    return lockedReceive()
  }
  function lockedReceive(): Promise<void> | null {
    if (loading.value) {
      return null
    }

    return receive()
  }
  async function receive(): Promise<void> {
    setLoading(true)

    return await profileApi.retrieve.execute()
      .then(({ item = {} }) => {
        setUser(new UserClass(item))
      })
      .catch((error: Response) => {
        setUser(new UserClass({}))
        console.warn(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function patch(payload: Record<string, any>) {
    return profileApi.update.execute({}, payload)
      .then(({ data: { item = {} } = {} }) => {
        updateUser(item)
        return item
      })
  }

  return {
    loading,
    user,
    isAuthenticated,
    isDetermined,
    setUser,
    updateUser,
    setLoading,
    initialReceive,
    lockedReceive,
    receive,
    patch,
  }
})
