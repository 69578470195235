import { defineAsyncComponent, shallowRef, DefineComponent, markRaw } from 'vue';
import { useModalOpener } from "./useModalOpener"

const UiConfirm = defineAsyncComponent(() => import('@/components/ui/modals/ModalConfirm.vue'))

export interface UseConfirmDialogConfig {
  question: string
  description?: string
  approvalText?: string
  danger?: boolean
}
const promise = (result: boolean) => new Promise((res, rej) => {
  if (result) res(result)
  rej(result)
})

export function useConfirmDialog(config: UseConfirmDialogConfig): Promise<unknown> {
  const {
    question,
    description,
    approvalText,
    danger = false,
  } = config


  return new Promise((resolve, reject) => {
    const onFinish = (value: boolean) => promise(value)
      .then(a => {
        resolve(a)
        close()
      })
      .catch(e => {
        reject(e)
        close()
      })
    function closeAndReject() {
      onFinish(false)
    }

    const { open, close } = useModalOpener({
      component: shallowRef(UiConfirm),
      question,
      description,
      danger,
      approvalText,
    }, {
      onClosed() {
        closeAndReject()
      },
    }, onFinish)
    open()
  })
}
