import { App, defineAsyncComponent } from 'vue'

import UserBar from './UserBar.vue'
import UserNotificationBar from './UserNotificationBar.vue'
const PriceIndices = defineAsyncComponent(() => import('./PriceIndices.vue'))

export default {
  install(app: App<Element>): void {
    app
      .component('UserBar', UserBar)
      .component('UserNotificationBar', UserNotificationBar)
      .component('PriceIndices', PriceIndices)
    }
}
