import { App, defineAsyncComponent } from 'vue'

const ESign = defineAsyncComponent(
  () => import('@/components/forms/ESign/ESign.vue')
)

const install = (app: App<Element>): void => {
  const prefix = 'Form'
  app
    .component(`ESign${prefix}`, ESign)
}

export default {
  install,
}