import { App, defineAsyncComponent } from 'vue'

import OrdersCatalog from './OrdersCatalog.vue'

const install = (app: App<Element>): void => {
  const prefix = 'Public'
  app.component(`${prefix}OrdersCatalog`, OrdersCatalog)
}

export default {
  install
}