import {
  defineAsyncComponent,
  computed,
  provide,
  ref,
  createApp,
  App,
  watch,
} from 'vue'
import { createPinia } from 'pinia'
import VueSlider from 'vue-slider-component'
import {
  // Directives
  vTooltip,
  // Components
  Dropdown,
} from 'floating-vue'
import {
  createRouter,
  createWebHistory,
  useRoute,
  RouteParams,
  RouteLocationNormalizedLoaded,
  RouteRecordName,
} from 'vue-router'
import { useI18n } from 'vue-i18n'
import createAppRouter from '@/router'
import i18n from './i18n'

import { vIntersectionObserver, vOnClickOutside } from '@vueuse/components'
import VueDatePicker from 'vue-datepicker-next'

import Components from '@/components'
import Modules from '@/modules'
import SvgIcons from '@/svg-icons'
import Plugins from '@/plugins'
import Directives from '@/directives'

type CreateApplication = {
  createApp: typeof createApp
  createRouter: typeof createRouter
  createWebHistory: typeof createWebHistory
  createPinia: typeof createPinia
}

export function createApplication({
  createApp,
  createRouter: createRouterInstance,
  createWebHistory,
  createPinia,
}: CreateApplication): App {
  const app = createApp({
    setup() {
      const route: RouteLocationNormalizedLoaded = useRoute()
      const { t: $t } = useI18n()

      const updatedTitle = ref<{ title: string; subTitle?: string } | null>(
        null
      )

      const currentTitle = computed(() => {
        if (updatedTitle.value?.title) {
          return updatedTitle.value
        }
        return 'function' === typeof route.meta.title
          ? route.meta.title?.(route.params as RouteParams) ||
              $t('ESKA FINANCE')
          : route.meta.title || $t('ESKA FINANCE')
      })

      provide('currentTitle', currentTitle)
      provide(
        'updateCurrentTitle',
        (title: { title: string; subTitle?: string } | null) => {
          updatedTitle.value = title
        }
      )

      // custom history.back logic
      const routeFromName = ref<RouteRecordName | null>(null)

      watch(
        () => route.name,
        (to, from) => {
          routeFromName.value = from
        }
      )

      provide(
        'previousRouteName',
        computed(() => routeFromName.value)
      )

      function iOS() {
        return (
          [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod',
          ].includes(navigator.platform) ||
          // iPad on iOS 13 detection
          (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
        )
      }
      if (iOS()) {
        const tag = document.createElement('meta')
        tag.setAttribute('name', 'viewport')
        tag.setAttribute(
          'content',
          'width=device-width, initial-scale=1.0,maximum-scale=1,user-scalable=0'
        )
        document.head.appendChild(tag)
      }
      return {}
    },
  })

  app.config.compilerOptions.delimiters = ['[[', ']]']

  const pinia = createPinia()

  const router = createAppRouter({
    app,
    createInstance: createRouterInstance,
    createWebHistory,
    pinia,
  })

  app.config.globalProperties.$log = console.log // you can use $log in template
  app.config.globalProperties.$goToTop = () =>
    document.body.scrollIntoView({ block: 'start', behavior: 'smooth' })
  app.config.globalProperties.$copyToClipboard = function copyToClipboard(
    value: string,
    callBack: (r: boolean) => void
  ) {
    // HTTPS ок localhost
    // use $toast after install it
    navigator.clipboard
      .writeText(value)
      .then(() => {
        callBack(true)
      })
      .catch(() => {
        callBack(false)
      })
  }
  app.config.compilerOptions.delimiters = ['[[', ']]'] // use this delimiters in pug(jinja)-templates

  app
    .directive('tooltip', vTooltip)
    .directive('on-click-outside', vOnClickOutside)
    .directive('intersection-observer', vIntersectionObserver)
    .component('VueSlider', VueSlider)
    // .component('Dropdown', Dropdown)
    .component('DatePicker', VueDatePicker)
    .use(SvgIcons)
    .use(Components)
    .use(Modules)
    .use(Plugins)
    .use(Directives)
    .use(pinia)
    .use(i18n)
    .use(router)
    .mount('#app')

  return app
}
