import { pick, omit } from 'ramda'

// const atob = (str: string) => {
//   return Buffer.from(str || '', 'base64').toString('binary');
// }
// const btoa = (str: string) => {
//   return Buffer.from(str || '', 'binary').toString('base64');
// }

export function b64EncodeUnicode(str: string): string {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) =>
      String.fromCharCode(Number(`0x${p1}`))
    )
  )
}

export function b64DecodeUnicode(str: string): string {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str || '')
      .split('')
      .map((c: string) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  )
}

const DROP_KEYS = ['limit', 'total', 'offset', '__internal__', 'order_by', 'sort']
const PARAMETERS_KEYS = ['limit', 'offset', 'order_by', 'sort', 'search']

export function parametersTo(
  parameters: Record<string, unknown>
): Record<string, unknown> {
  if (!parameters.offset) {
    parameters.offset = undefined
  }
  return {
    ...pick(PARAMETERS_KEYS, parameters),
    filters: b64EncodeUnicode(
      JSON.stringify(omit(DROP_KEYS, parameters.filters) || {})
    ),
  }
}

export function parametersFrom({
  filters,
  ...rest
}: {
  filters: string
  [key: string]: unknown
}): Record<string, unknown> {
  let data: Record<string, unknown> = {}

  try {
    data = JSON.parse(b64DecodeUnicode(filters || '{}'))
  } catch (e) {
    console.warn(e)
  }
  // parse route path for initial filters from route params

  const initial = Object.keys(rest).filter((el) => !DROP_KEYS.includes(el))
  initial.forEach((key: string) => {
    data[key] = { id: rest[key] }
  })

  return { ...rest, filters: data }
}
