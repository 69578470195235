import { r, createSender, createRemover } from '@/plugins/resource/resource'
import { queryPostfix as q } from '@/plugins/resource/api'

const MODEL = 'orders'
const OWN_MODEL = MODEL + '/own'
const AUXILIARY_MODEL = MODEL + '/auxiliary'
const TEMPLATES_MODEL = MODEL + '/templates'

export const ordersApi = {
  list: r(q('/list/', ['filters']), MODEL),
  filtersList: r('/list/filters/', MODEL),
  retrieve: r('{/id}/retrieve/', MODEL),
  actions: {
    archive: r('{/id}/archive/', MODEL, createSender),
  },
  own: {
    list: r(q('/list/', ['filters']), OWN_MODEL),
    filtersList: r('/list/filters/', OWN_MODEL),
    retrieve: r('{/id}/retrieve/', OWN_MODEL),
    update: r('{/id}/update/', OWN_MODEL, createSender).config('method', 'PATCH'),
    create: r('/create/', OWN_MODEL, createSender),
    actions: {
      archive: r('{/id}/archive/', OWN_MODEL, createSender),
    },
  },
  templates: {
    list: r(q('/list/', ['filters']), TEMPLATES_MODEL),
    simpleList: r(q('/simple-list/', ['filters']), TEMPLATES_MODEL),
    filtersList: r('/list/filters/', TEMPLATES_MODEL),
    create: r('/create/', TEMPLATES_MODEL, createSender),
    getNextName: r('/create/next-name{/market_type}/', TEMPLATES_MODEL),
    retrieve: r('{/id}/retrieve/', TEMPLATES_MODEL),
    update: r('{/id}/update/', TEMPLATES_MODEL, createSender).config('method', 'PATCH'),
    delete: r('{/id}/delete/', TEMPLATES_MODEL, createRemover).config('method', 'DELETE'),
  },
  auxiliary: {
    statusesList: r('/statuses/list/', AUXILIARY_MODEL),
    marketTypesList: r('/market-types/list/', AUXILIARY_MODEL),
    pricingTypesList: r('/pricing-types/list/', AUXILIARY_MODEL),
    matchTypesList: r('/match-types/list/', AUXILIARY_MODEL),
  },
}
