import { computed, ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'

import { notificationsApi } from '@/services/notifications.service'
import { useAuthStore } from '@m/auth/store'
import { CompanyAgreementTypes, VERIFICATION_INDICATORS } from '@/const'

interface NotificationStats {
  recipient?: {
    id: string | number
    type: string
    display: string
  }
  flags: {
    pk: number
    count: number
  }[]
}

export const useUserStore = defineStore('userStore', () => {
  const authStore = useAuthStore()

  const statsInfo = ref<NotificationStats>({
    flags: [],
  })

  const displayName = computed(() => {
    if (!authStore.user.isAuthenticated()) {
      return null
    }

    const { user } = storeToRefs(authStore)
    const {
      username,
      fullName,
      displayName: userDisplayName,
      firstName,
      lastName,
    } = user.value.info

    return (
      userDisplayName ||
      username ||
      fullName ||
      (firstName || '-') + ' ' + (lastName || '-')
    )
  })

  const userInfo = computed(() => {
    if (!authStore.user.isAuthenticated()) {
      return {}
    }
    return authStore.user?.info
  })

  const userCompanyAgreement = computed<string[]>(() => {
    if (!authStore.user.isAuthenticated()) {
      return []
    }
    return (userInfo.value?.agreements as string[]) || []
  })

  const canCreateOffers = computed(() => {
    return (
      userInfo.value?.companyVerificationStatus &&
      VERIFICATION_INDICATORS.PASSED ===
        userInfo.value?.companyVerificationStatus &&
      userCompanyAgreement.value.includes(CompanyAgreementTypes.sell)
    )
  })
  const canCreateApplication = computed(() => {
    return (
      userInfo.value?.companyVerificationStatus &&
      VERIFICATION_INDICATORS.PASSED ===
        userInfo.value?.companyVerificationStatus &&
      userCompanyAgreement.value.includes(CompanyAgreementTypes.purchase)
    )
  })

  async function fetchStatsInfo() {
    return await notificationsApi.notifications.stats
      .execute()
      .then(({ items }: { items: NotificationStats[] }) => {
        statsInfo.value = items && items[0]
      })
  }

  return {
    displayName,
    userInfo,
    userCompanyAgreement,
    canCreateOffers,
    canCreateApplication,
    statsInfo,
    fetchStatsInfo,
  }
})
