function handleClick(this: HTMLElement, offset: number): void {
  const elRect = this.getBoundingClientRect()
  const coord = elRect.top + window.scrollY + offset

  window.scroll({ top: coord, behavior: 'smooth' })
}
export const scroll = {
  mounted: (
    el: HTMLElement,
    binding: { value: number } = { value: 0 }
  ): void => {
    const offset = Math.round(binding.value)

    el.addEventListener('click', handleClick.bind(el, offset), {
      passive: true,
    })
  },
  beforeUnmount: (el: HTMLElement): void => {
    el.removeEventListener('click', handleClick as any)
  },
}
