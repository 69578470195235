import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "g-row g-row--appearance_spaced g-row--justify_between g-row--space_sm" }
const _hoisted_2 = { class: "g-cell g-cols g-cols--auto" }
const _hoisted_3 = { class: "g-cell g-cols g-cols--12 u-display u-display--block-xl" }
const _hoisted_4 = {
  key: 0,
  class: "g-row"
}
const _hoisted_5 = { class: "g-cell g-cols g-cols--narrow" }
const _hoisted_6 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingWrapper = _resolveComponent("LoadingWrapper")!

  return (_openBlock(), _createBlock(_component_LoadingWrapper, { loading: $props.loading }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          ($setup.filters.length)
            ? (_openBlock(), _createBlock($setup["FilterUi"], {
                key: 0,
                disabled: $props.loading,
                value: $setup.prepared,
                permanent: $props.permanent,
                filters: $setup.filters,
                onInput: $setup.handleInput,
                onFiltrate: $setup.filtrate,
                onClear: $setup.clear,
                all: $props.all
              }, _createSlots({
                "reset-section": _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    (!$props.hideFiltersControls)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("button", {
                              class: "ds-link ds-link--styling_secondary",
                              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.clear()), ["prevent"])),
                              disabled: $props.loading
                            }, _toDisplayString(_ctx.$t("Скинути")), 9, _hoisted_6)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                append: _withCtx((scope) => [
                  _renderSlot(_ctx.$slots, "append", _normalizeProps(_guardReactiveProps({ ...scope, all: $props.all })))
                ]),
                _: 2
              }, [
                _renderList(_ctx.$slots, (slot, key) => {
                  return {
                    name: key,
                    fn: _withCtx((scope) => [
                      _renderSlot(_ctx.$slots, key, _normalizeProps(_guardReactiveProps(scope)))
                    ])
                  }
                })
              ]), 1032, ["disabled", "value", "permanent", "filters", "all"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 3
  }, 8, ["loading"]))
}