import { RouteRecordRaw } from 'vue-router'
import { route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'

const DOCUMENTS_PREFIX = 'cabinet:documents'
const p = (name: string) => DOCUMENTS_PREFIX + ':' + name

export const DOCUMENTS_LIST = p('list')

const DocumentsView = () => import('./views/DocumentsView.vue')

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route('list/', DocumentsView, DOCUMENTS_LIST, {
      meta: {
        title: $t('Мої документи'),
      }
    }),
    { path: '', redirect: { name: DOCUMENTS_LIST } },
  ]
}
