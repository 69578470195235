import { createApp, App } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createPinia } from 'pinia'
import { createApplication } from '@/app'

import 'vue-datepicker-next/locale/uk'
import 'vue-slider-component/theme/default.css'
import 'floating-vue/dist/style.css'
import 'vue-final-modal/style.css'
import 'vue-datepicker-next/index.css'
import './styles/index.sass'

export const app = createApplication({
  createApp,
  createRouter,
  createWebHistory,
  createPinia,
})

window.app = app

declare global {
  interface Window {
    IS_AUTH: number | false
    ACCESS_TOKENS: string[]
    u_rl: 'A1' | 'A2'
    ALL_ACCESS_TOKENS: Record<string, any>
    default_language: string
    app: App
    topics: { id: number; name: string; isDefault: boolean }[]
    captcha_key: string
    login_url: string
    logo_cabinet: string
  }
}
