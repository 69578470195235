import { defineAsyncComponent } from 'vue'
import { WidgetsMap } from './useGenerator'
import { disableAfterDate } from '@utils/helpers'

// const MultiselectWidget = () => import('@md/ui/filter/widgets/MultiselectWidget');
const DatePickerWidget = defineAsyncComponent(
  () => import('./widgets/DatePickerWidget.vue')
)
const SelectWidget = defineAsyncComponent(
  () => import('./widgets/SelectWidget.vue')
)
// const CheckboxWidget = () => import('./widgets/CheckboxWidget');
const SearchWidget = defineAsyncComponent(
  () => import('./widgets/SearchWidget.vue')
)
const AutocompleteWidget = defineAsyncComponent(
  () => import('./widgets/AutocompleteWidget.vue')
)
const SliderWidget = defineAsyncComponent(
  () => import('./widgets/SliderWidget.vue')
)

const defaultMultiselectConfig = {
  multiple: true,
  trackBy: 'id',
  label: 'caption',
  showLabels: true,
  closeOnSelect: true,
  searchable: false,
}

export const defaultDatePickerConfig = {
  // clearable: false,
  // enableTimePicker: false,
  // locale: 'uk',
  // monthNameFormat: 'long',
  // position: 'right',
  // ignoreTimeValidation: true,
}

export const WIDGETS_MAP: WidgetsMap = {
  multiselect: {
    component: SelectWidget,
    config: {
      ...defaultMultiselectConfig,
      closeOnSelect: true,
      'close-on-select': true,
    },
  },
  // 'multiselect-autocomplete': {
  //   component: AutocompleteMultiselectWidget,
  //   config: {
  //     ...defaultMultiselectConfig,
  //     label: 'label',
  //     searchable: true,
  //     internalSearch: false,
  //   },
  // },
  select: {
    component: SelectWidget,
    config: {
      ...defaultMultiselectConfig,
      multiple: false,
    },
  },
  search: {
    component: SearchWidget,
    config: {},
  },
  autocomplete: {
    component: AutocompleteWidget,
    config: {},
  },
  'multiselect-autocomplete': {
    component: AutocompleteWidget,
    config: {
      multiple: false,
    },
  },
  'decimal-range': {
    component: SliderWidget,
    config: {
      'tooltip-placement': 'bottom',
      contained: true,
      'dot-size': 14,
    },
  },
  'datetime-range': {
    component: DatePickerWidget,
    config: {
      ...defaultDatePickerConfig,
      disabledDate: disableAfterDate({ date: new Date() }),
      disabledCalendarChanger: disableAfterDate({ date: new Date() }),
    },
  },
  'date-range': {
    component: DatePickerWidget,
    config: {
      ...defaultDatePickerConfig,
      dateFormat: 'date',
      pickerType: 'date',
      rangeConfig: 'date',
      range: true,
      // 'disabled-date': disableAfterDate({ date: new Date() }),
      // 'disabled-calendar-changer': disableAfterDate({ date: new Date() }),
    },
  },
  // 'date-range-all': {
  //   component: DataRangeWidget,
  //   config: {...defaultDatePickerConfig},
  // },
  // 'date-month-range': {
  //   component: DataRangeWidget,
  //   config: {
  //     ...defaultDatePickerConfig,
  //     minDate: new Date(),
  //     monthPicker: true,
  //   },
  // },
  // 'date-month-range-all': {
  //   component: DataRangeWidget,
  //   config: {
  //     ...defaultDatePickerConfig,
  //     monthPicker: true,
  //   },
  // },
}
