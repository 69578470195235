import { RouteRecordRaw } from 'vue-router'
import { route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'

const NOTIFICATIONS_PREFIX = 'cabinet:notifications'
const p = (name: string) => NOTIFICATIONS_PREFIX + ':' + name

export const NOTIFICATIONS_LIST = p('list')
export const NOTIFICATIONS_SETTINGS = p('settings')

const NotificationsView = () => import('./views/NotificationsView.vue')
const NotificationsSettings = () => import('./views/NotificationsSettings.vue')

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route('list/', NotificationsView, NOTIFICATIONS_LIST, {
      meta: {
        title: $t('Мої повідомлення'),
      }
    }),
    route('settings/', NotificationsSettings, NOTIFICATIONS_SETTINGS, {
      meta: {
        title: $t('Налаштування повідомленнь'),
      }
    }),
    { path: '', redirect: { name: NOTIFICATIONS_LIST } },
  ]
}
