import { h, computed, ref, SetupContext, Fragment, VNode, DefineComponent, resolveDynamicComponent } from 'vue';

const name = 'control-checker';

interface Props {
  b: string
  tag: string
  type: string
  styling: string
  checked: boolean
  disabled: boolean
  invalid: boolean
  readonly: boolean
  modelValue: boolean
}

export default function Checker(props: Record<string, unknown>, { emit, attrs }: Pick<SetupContext, 'attrs' | 'slots' | 'emit'>): VNode {
  // name,

  // props: {
  //   b: { default: name },
  //   tag: { default: 'label' },
  //   type: { default: 'checkbox' },
  //   styling: { default: 'default' },
  //   checked: { type: Boolean, default: false },
  //   disabled: { type: Boolean, default: false },
  //   invalid: { type: Boolean, default: false },
  //   readonly: { type: Boolean, default: false },
  // },

  const {
    b = name,
    type = 'checkbox',
    disabled = false,
    invalid = false,
    readonly = false,
  } = props
  const styling = ref(props.styling || 'default')

  return h(
    'label',
    // resolveDynamicComponent('tag') as DefineComponent,
    {
      tag: 'label',
      class: [
        b,
        {
          [`${b}--styling_${styling.value}`]: !!styling.value,
          [`${b}--type_${type}`]: !!type,
        },
        {
          'is-checked': props.checked,
          'is-disabled': disabled,
          'is-invalid': invalid,
          // checkbox doesn't have readonly attr
          'is-readonly': readonly,
        },
      ],
    },
    [
      h(
        'input',
        {
          b: undefined,
          class: `${b}__element`,
          '^type': type,
          '^checked': !!props.checked || null,
          '^disabled': disabled || null,
          'onChange': (e: Event) => { emit('update:modelValue', (e.target as HTMLInputElement).checked); },
          ...attrs,
        }
      ),
      h(
        'span',
        { class: `${b}__label` },
      ),
    ]
  )
}

Checker.props = [
  'b',
  'tag',
  'type',
  'checked',
  'disabled',
  'invalid',
  'readonly',
  'styling',
  'modelValue',
]
Checker.emits = ['update:modelValue']

Checker.inheritAttrs = false
