import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ds-caption ds-caption--variant_empty-list"
}
const _hoisted_2 = { class: "ds-table__row ds-table__row--variant_expanded" }
const _hoisted_3 = ["colspan"]
const _hoisted_4 = { class: "table-wrapper" }
const _hoisted_5 = { class: "ds-table ds-table--styling_primary" }
const _hoisted_6 = { class: "ds-table__body" }
const _hoisted_7 = { class: "ds-table__row" }
const _hoisted_8 = { class: "ds-table__cell" }
const _hoisted_9 = { class: "ds-table__cell" }
const _hoisted_10 = { class: "ds-table__row" }
const _hoisted_11 = { class: "ds-table__cell" }
const _hoisted_12 = { class: "ds-table__cell" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "ds-table__row" }
const _hoisted_17 = { class: "ds-table__cell" }
const _hoisted_18 = { class: "ds-table__cell" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingWrapper = _resolveComponent("LoadingWrapper")!

  return (_openBlock(), _createBlock(_component_LoadingWrapper, {
    loading: $props.filterController?.loading
  }, {
    default: _withCtx(() => [
      (!$props.filterController?.loading && $setup.items && $setup.items.length === 0)
        ? (_openBlock(), _createElementBlock("h4", _hoisted_1, _toDisplayString(_ctx.$t("Не знайдено жодної заявки")), 1))
        : (_openBlock(), _createBlock($setup["ListWrapper"], {
            key: 1,
            "market-type": $props.marketType,
            orderable: true,
            ordering: $props.filterController.ordering,
            items: $setup.items,
            "multi-expandable": true,
            "key-getter": $setup.keyGetter,
            "expanded-key-getter": $setup.keyGetter,
            "onUpdate:ordering": $props.filterController.changeOrdering
          }, _createSlots({
            expanded: _withCtx((scope) => [
              _createElementVNode("tr", _hoisted_2, [
                _createElementVNode("td", {
                  class: "ds-table__cell",
                  colspan: scope.cells
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("table", _hoisted_5, [
                      _createElementVNode("tbody", _hoisted_6, [
                        _createElementVNode("tr", _hoisted_7, [
                          _createElementVNode("td", _hoisted_8, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t("Години")), 1)
                          ]),
                          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(24, (i, index) => {
                            return _createElementVNode("td", _hoisted_9, [
                              _createElementVNode("span", null, _toDisplayString(i), 1)
                            ])
                          }), 64))
                        ]),
                        _createElementVNode("tr", _hoisted_10, [
                          _createElementVNode("td", _hoisted_11, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t("Обсяг, мВт")), 1)
                          ]),
                          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(24, (i, index) => {
                            return _createElementVNode("td", _hoisted_12, [
                              (scope.item.scheduleHoursFilled.includes(i))
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    (-1 !== scope.item.schedule.findIndex(el => el.hour == i))
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(Number(scope.item.schedule[scope.item.schedule.findIndex(el => el.hour === i)].volume)), 1))
                                      : (_openBlock(), _createElementBlock("span", _hoisted_14, "-"))
                                  ], 64))
                                : (_openBlock(), _createElementBlock("span", _hoisted_15, "-"))
                            ])
                          }), 64))
                        ]),
                        _createElementVNode("tr", _hoisted_16, [
                          _createElementVNode("td", _hoisted_17, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t("Ціна, грн")), 1)
                          ]),
                          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(24, (i, index) => {
                            return _createElementVNode("td", _hoisted_18, [
                              (scope.item.scheduleHoursFilled.includes(i))
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    (-1 !== scope.item.schedule.findIndex(el => el.hour == i))
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(Number(scope.item.schedule[scope.item.schedule.findIndex(el => el.hour === i)].price)), 1))
                                      : (_openBlock(), _createElementBlock("span", _hoisted_20, "-"))
                                  ], 64))
                                : (_openBlock(), _createElementBlock("span", _hoisted_21, "-"))
                            ])
                          }), 64))
                        ])
                      ])
                    ])
                  ])
                ], 8, _hoisted_3)
              ])
            ]),
            _: 2
          }, [
            _renderList(_ctx.$slots, (slot, key) => {
              return {
                name: key,
                fn: _withCtx((scope) => [
                  _renderSlot(_ctx.$slots, key, _normalizeProps(_guardReactiveProps(scope)))
                ])
              }
            })
          ]), 1032, ["market-type", "ordering", "items", "onUpdate:ordering"]))
    ]),
    _: 3
  }, 8, ["loading"]))
}