import { r, createSender, createFileSender } from '@/plugins/resource/resource'
import { queryPostfix as q } from '@/plugins/resource/api'

const MODEL = 'company'
const DOCUMENTS_MODEL = MODEL + '{/id}/documents'
const CHANGE_REQUEST_MODEL = MODEL + '/change-request'
const VERIFICATION_REQUEST_MODEL = MODEL + '/verification-request'

export const companyApi = {
  retrieve: r('{/id}/', MODEL),
  retrieveOwn: r('/', MODEL),
  list: r(q('/list/', ['filters']), MODEL),
  filtersList: r('/list/filters/', MODEL),
  changeVerificationStatus: r('{/id}/change-verification-status/', MODEL, createSender),
  agreement: {
    list: r('{/id}/agreement/list/', MODEL),
    sign: r('/agreement{/type}/sign/', MODEL, createFileSender),
  },
  changeRequest: {
    send: r('/', CHANGE_REQUEST_MODEL, createSender),
    list: r(q('/list/', ['filters']), CHANGE_REQUEST_MODEL),
    ownList: r(q('/own/list/', ['filters']), CHANGE_REQUEST_MODEL),
    filtersList: r('/list/filters/', CHANGE_REQUEST_MODEL),
    handle: r('{/id}/handle/', CHANGE_REQUEST_MODEL, createSender),
  },
  checks: r('/checks/', MODEL),
  documents: {
    retrieve: r('/', DOCUMENTS_MODEL),
    factorDocumentTypes: r('/factor-document-types/{?contains_factors,exclude_factors}', DOCUMENTS_MODEL),
    update: r('/update/', DOCUMENTS_MODEL, createSender).config('method', 'PATCH'),
    upload: r('/upload/', DOCUMENTS_MODEL, createFileSender)
  },
  update: r('/update/', MODEL, createSender).config('method', 'PATCH'),
  verificationRequest: {
    create: r('/', VERIFICATION_REQUEST_MODEL, createSender).config('method', 'PATCH'),
    list: r(q('/list/', ['filters']), VERIFICATION_REQUEST_MODEL),
    ownList: r(q('/own/list/', ['filters']), VERIFICATION_REQUEST_MODEL),
    filtersList: r('/list/filters/', VERIFICATION_REQUEST_MODEL),
    handle: r('{/id}/handle/', VERIFICATION_REQUEST_MODEL, createSender),
  },
}
