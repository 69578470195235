import { i18n } from './i18n'

const { t } = i18n.global

export type Pagination = {
  limit: number
  offset: number
  total: number
}

export enum UserRoles {
  'admin' = 'admin',
  'client' = 'A1',
  'manager' = 'A2',
}

export const CURRENT_CURRENCY = t('грн')

export enum CompanyAgreementTypes {
  'cooperation' = 'cooperation_agreement',
  'sell' = 'sell_agreement',
  'purchase' = 'purchase_agreement',
}

export type AuxiliaryItem = {
  id: string
  caption: string
  key: string
}

export enum MarketTypes {
  buy = '010-buy',
  sell = '020-sell',
}
export const MarketTypesTitles = {
  [MarketTypes.buy]: t('Покупка'),
  [MarketTypes.sell]: t('Продаж'),
}

export enum DealsStatus {
  '010-price-calculation' = 0,
  '033-unpaid' = 1,
  '036-paid' = 2,
  '042-dispatch-accepted' = 3,
  '043-dispatch-awaiting' = 4,
  '046-dispatch-failed' = 5,
  '080-success' = 6,
  '093-rejected' = 7,
  '096-cancelled' = 8,
}
export const DealsStatusTitles = {
  [DealsStatus[0]]: t('Перевірка ціни'),
  [DealsStatus[1]]: t('Очікує оплати'),
  [DealsStatus[2]]: t('Оплачено'),
  [DealsStatus[3]]: t('Доставка прийнята'),
  [DealsStatus[4]]: t('Очікує відправки'),
  [DealsStatus[5]]: t('Не відправлено'),
  [DealsStatus[6]]: t('Успішна'),
  [DealsStatus[7]]: t('Відхилено'),
  [DealsStatus[8]]: t('Відміна'),
}
export const DealsStatusTitlesForSeller = {
  [DealsStatus[0]]: t('Очікує відправки'),
  [DealsStatus[1]]: t('Очікує відправки'),
  [DealsStatus[2]]: t('Очікує відправки'),
  [DealsStatus[3]]: t('Очікує відправки'),
  [DealsStatus[4]]: t('Очікує відправки'),
  [DealsStatus[5]]: t('Не відправлено'),
  [DealsStatus[6]]: t('Успішна'),
  [DealsStatus[7]]: t('Відхилено'),
  [DealsStatus[8]]: t('Відміна'),
}

export const DealsStatusTitlesForBuyer = {
  [DealsStatus[0]]: t('Очікує оплати'),
  [DealsStatus[1]]: t('Очікує оплати'),
  [DealsStatus[2]]: t('Оплачено'),
  [DealsStatus[3]]: t('Очікує відправки'),
  [DealsStatus[4]]: t('Очікує відправки'),
  [DealsStatus[5]]: t('Очікує відправки'),
  [DealsStatus[6]]: t('Успішна'),
  [DealsStatus[7]]: t('Успішна'),
  [DealsStatus[8]]: t('Відміна'),
}
export const DealsStatusGroupForBuyer = [
  {
    title: t('Очікує оплати'),
    statuses: [DealsStatus[0], DealsStatus[1]],
  },
  {
    title: t('Оплачено'),
    statuses: [DealsStatus[2], DealsStatus[3], DealsStatus[4], DealsStatus[5]],
  },
  {
    title: t('Відхилено'),
    statuses: [DealsStatus[7]],
  },
  {
    title: t('Успішна'),
    statuses: [DealsStatus[6]],
  },
  {
    title: t('Відміна'),
    statuses: [DealsStatus[8]],
  },
]
export const DealsStatusGroupForSeller = [
  {
    title: t('Очікує відправки'),
    statuses: [DealsStatus[0], DealsStatus[1], DealsStatus[2]],
  },
  {
    title: t('Не відправлено'),
    statuses: [DealsStatus[5]],
  },
  {
    title: t('Успішна'),
    statuses: [DealsStatus[3], DealsStatus[6]],
  },
  {
    title: t('Відміна'),
    statuses: [DealsStatus[7], DealsStatus[8]],
  },
]

export enum PricingTypes {
  fixed = '010-fixed',
  discount = '020-discount',
}
export const PricingTypesTitles = {
  [PricingTypes.fixed]: t('Фіксована вартість'),
  [PricingTypes.discount]: t('Дисконт'),
}
export enum MatchTypes {
  total = '010-total',
  partial = '020-partial',
}
export const MatchTypesTitles = {
  [MatchTypes.partial]: t('Звичайна'),
  [MatchTypes.total]: t('Блочна'),
}

export enum OrderStatus {
  active = '010-active',
  bought = '030-bought',
  archived = '090-archived',
}

export const OrderStatuses = {
  [OrderStatus.active]: t('Активне'),
  [OrderStatus.bought]: t('Угода'),
  [OrderStatus.archived]: t('Архів'),
}

export const CURRENT_LOCALE = window.language

export const DEFAULT_DAY_OFFSET = 2

export const NOTIFICATIONS_FLAGS = {
  UNREAD: 73232,
  READ: 73236,
  OFFER: 10,
  ORDER: 11,
  DEAL: 12,
  OTHER: 13,
}

export const VERIFICATION_INDICATORS = {
  NO_VERIFICATION: '1000',
  ON_VERIFICATION: '2000',
  NEED_INFORMATION: '3000',
  PASSED: '4000',
  CANCEL: '5000',
}

export const TYPE_DELIVERY = {
  warehouse: 'warehouse',
  address: 'address',
}

export enum REQUEST_STATUSES {
  'NOT_PROCESSED',
  'PROCESSED',
}

export const TABLE_CELL_CLASS_BY_REQUEST_STATUS_MAP = {
  [REQUEST_STATUSES[0]]: 'table-cell-status-not-processed',
  [REQUEST_STATUSES[1]]: 'table-cell-status-processed',
  '1000': 'table-cell-status-processed',
  '2000': 'table-cell-status-not-processed',
  '030-succeeded': 'table-cell-status-processed',
  '090-failed': 'table-cell-status-processed',
  '010-in-process': 'table-cell-status-not-processed',
}

enum BALANCE_WITHDRAWAL_STATUS {
  '010-in-process' = 0,
  '030-succeeded' = 1,
  '090-failed' = 2,
}

export const BalanceWithdrawalStatus = {
  [BALANCE_WITHDRAWAL_STATUS[0]]: t('Очікує'),
  [BALANCE_WITHDRAWAL_STATUS[1]]: t('Оброблено'),
  [BALANCE_WITHDRAWAL_STATUS[2]]: t('Відхилено'),
}
enum BALANCE_REPLENISHMENT_STATUS {
  '010-in-process' = 0,
  '030-succeeded' = 1,
  '090-failed' = 2,
}

export const BalanceReplenishmentStatus = {
  [BALANCE_REPLENISHMENT_STATUS[0]]: t('Очікує'),
  [BALANCE_REPLENISHMENT_STATUS[1]]: t('Оброблено'),
  [BALANCE_REPLENISHMENT_STATUS[2]]: t('Відхилено'),
}

export enum DealHistoryTypes {
  'default' = 0,
  'comment' = 1,
  'created' = 2,
  'status-change' = 3,
  'payment' = 4,
}

export const NotificationsTypesTitles = {
  [NOTIFICATIONS_FLAGS.OFFER]: t('Пропозиція'),
  [NOTIFICATIONS_FLAGS.ORDER]: t('Заявка'),
  [NOTIFICATIONS_FLAGS.DEAL]: t('Угода'),
  [NOTIFICATIONS_FLAGS.OTHER]: t('Інше'),
}

export const NotificationsTypes = [
  {
    id: NOTIFICATIONS_FLAGS.OFFER,
    title: NotificationsTypesTitles[NOTIFICATIONS_FLAGS.OFFER],
  },
  {
    id: NOTIFICATIONS_FLAGS.ORDER,
    title: NotificationsTypesTitles[NOTIFICATIONS_FLAGS.ORDER],
  },
  {
    id: NOTIFICATIONS_FLAGS.DEAL,
    title: NotificationsTypesTitles[NOTIFICATIONS_FLAGS.DEAL],
  },
  {
    id: NOTIFICATIONS_FLAGS.OTHER,
    title: NotificationsTypesTitles[NOTIFICATIONS_FLAGS.OTHER],
  },
]
