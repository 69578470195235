/* eslint-disable */

import { i18n } from '@/i18n'
// import { isValidPhoneNumber } from 'libphonenumber-js'

const { t: $t, d: $d, n: $n } = i18n.global

export function isNullOrUndefined(value: unknown): value is undefined | null {
  return value === null || value === undefined
}

export function isEmptyArray(arr: unknown): boolean {
  return Array.isArray(arr) && arr.length === 0
}

export function getSingleParam<TParam = unknown>(
  params: [TParam] | Record<string, TParam>,
  paramName: string
) {
  return Array.isArray(params) ? params[0] : params[paramName]
}

export function isEmpty(value: unknown): boolean {
  if (value === null || value === undefined || value === '') {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  return false
}

function eic(this: any, value: any) {
  if (!value || !value.length) {
    return true
  }

  const EIC_REG = /^\d{2}[0-9A-Z-]{14}$/
  if (!EIC_REG.test(value.trim())) {
    return $t('You need to enter a valid EIC code')
  }
  return true
}

function address(this: any, value: any) {
  if (
    isNullOrUndefined(value) ||
    isEmptyArray(value) ||
    value === false ||
    !value
  ) {
    return $t('This field is required')
  }
  if (!value?.address || !value.lat || !value.lng) {
    return $t('This field is required')
  }
  return true
}

function required(this: any, value: any) {
  if (
    isNullOrUndefined(value) ||
    isEmptyArray(value) ||
    value === false ||
    value === ''
  ) {
    return $t('This field is required')
  }
  return true
}

function email(this: any, value: any) {
  if (!value || !value.length) {
    return true
  }

  const EMAILREG =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!EMAILREG.test(value)) {
    return $t('This field must be a valid email')
  }
  return true
}

function confirmed(this: any, value: any, [target]: string) {
  if (value === target) {
    return true
  }
  return $t('Password did not match')
}

function min(
  this: any,
  value: unknown,
  params: [string | number] | { length: string | number }
): boolean | string {
  if (isEmpty(value)) {
    return true
  }

  const length = getSingleParam(params, 'length')
  if (Array.isArray(value)) {
    return value.every((val) => min(val, { length }))
  }

  if (String(value).length >= Number(length)) {
    return String(value).length >= Number(length)
  } else {
    return `${$t('Min')}:${length}`
  }
}
const max = (
  value: unknown,
  params: [string | number] | { length: string | number }
): boolean => {
  if (isEmpty(value)) {
    return true
  }

  const length = getSingleParam(params, 'length')
  if (Array.isArray(value)) {
    return value.every((val) => max(val, { length }))
  }

  return [...String(value)].length <= Number(length)
}
function maxArrLength(
  this: any,
  value: unknown,
  params: [string | number] | { length: string | number }
): boolean | string {
  if (isEmpty(value)) {
    return true
  }

  const length = getSingleParam(params, 'length')
  if (Array.isArray(value)) {
    return value.length <= Number(length) || `${$t('Maximum')}: ${length}`
  } else {
    return `${$t('Maximum')}:${length}`
  }
}

function decimal(
  this: any,
  value: string,
  params: [string | number] | { length: string | number }
): boolean | string {
  const separator = '.'
  if (isEmpty(value)) {
    return true
  }
  const length = getSingleParam(params, 'length')
  const errorText = `${$t(
    'Ensure that the decimal places do not exceed'
  )} ${length}`
  if (isNullOrUndefined(value) || '' === value) {
    return errorText
  }

  if (Array.isArray(value)) {
    return value.every((val) => decimal(val, { length }))
  }

  // if is 0.
  if (0 === Number(length)) {
    return /^-?\d*$/.test(value) || errorText
  }
  const regexPart = '*' === length ? '+' : `{1,${length}}`
  const regex = new RegExp(
    `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`
  )

  if (!regex.test(value)) {
    return errorText
  }

  const parsedValue = parseFloat(value)

  // eslint-disable-next-line
  return parsedValue === parsedValue || errorText
}

function minArrLength(
  this: any,
  value: unknown,
  params: [string | number] | { length: string | number }
): boolean | string {
  const length = getSingleParam(params, 'length')
  if (isEmpty(value)) {
    return `${$t('Minimum')}: ${length}`
  }

  if (Array.isArray(value)) {
    return value.length >= Number(length) || `${$t('Minimum')}: ${length}`
  } else {
    return `${$t('Minimum')}: ${length}`
  }
}

function maxValue(
  this: any,
  value: unknown,
  params: [string | number] | { length: string | number }
): boolean | string {
  if (!value) {
    return true
  }
  const length = getSingleParam(params, 'length')
  if (Number(value) <= Number(length)) {
    return Number(value) <= Number(length)
  } else {
    return `${$t('Max')}: ${length}`
  }
}

function minValue(
  this: any,
  value: unknown,
  params: [string | number] | { length: string | number }
): boolean | string {
  const length = getSingleParam(params, 'length')
  if (Number(value) >= Number(length)) {
    return Number(value) >= Number(length)
  } else {
    return `${$t('Min')}: ${length}`
  }
}

function url(this: any, value: any) {
  if (!value || !value.length) {
    return true
  }

  const URLREG =
    /^(http(s?):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/
  if (!URLREG.test(value)) {
    return $t('This field must be a valid url')
  }
  return true
}

function postBackUrl(this: any, value: any) {
  if (!value || !value.length) {
    return true
  }
  const URLREG = /^(https?|chrome):\/\/[^\s$.?#].[^\s].+\?*={click_id}.*$/
  if (!URLREG.test(value.replace('%7B', '{').replace('%7D', '}'))) {
    return $t('This field must be a valid url')
  }

  return true
}
export const MOBILEREG = /^[+()-\d]+$/

export const EMAILREG =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

function emailOrPhone(this: any, value: any) {
  return (
    EMAILREG.test(value) ||
    MOBILEREG.test(value) ||
    $t('Set correct email or phone')
  )
}

// async function realPhone(this: any, value: any, [realValue]: string) {
//   const { isValidPhoneNumber } = await import('libphonenumber-js')
//   return isValidPhoneNumber(realValue) || $t('Phone is not valid')
// }

const maskedPhone = (
  value: unknown,
  params: [string | number] | { length: string | number }
): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const length = getSingleParam(params, 'length')
  if (Array.isArray(value)) {
    return value.every((val) => min(val, { length }))
  }

  if (String(value).replace(/[^\d]/g, '').length >= Number(length)) {
    return String(value).replace(/[^\d]/g, '').length >= Number(length)
  } else {
    const maskedPhoneErrorMessage = $t('Номер телефону має бути не менше')
    return `${maskedPhoneErrorMessage}: ${length} ${$t('символів')}`
  }
}

/*
 * Returns 1 if the IBAN is valid
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
function iban(input) {
  const CODE_LENGTH = 29
  const iban = String(input)
      .toUpperCase()
      .replaceAll(' ', '') // keep only alphanumeric characters
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/) // match and capture (1) the country code, (2) the check digits, and (3) the rest
  let digits
  // check syntax and length
  if (iban.length !== CODE_LENGTH) {
    return $t('Необхідна довжина') + ': '+ CODE_LENGTH + ' ' + $t('символів')
  }
  if (!code) {
    return $t('Невірний IBAN')
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(
    /[A-Z]/g,
    (letter: string): string => String(letter.charCodeAt(0) - 55)
  )
  // final check
  return mod97(digits) === 1 || $t('Невірний IBAN')
}

function mod97(string: string): number | string {
  let checksum: number = Number(string.slice(0, 2))
  let fragment
  for (var offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7)
    checksum = parseInt(fragment, 10) % 97
  }
  return checksum
}

export {
  url,
  required,
  address,
  eic,
  email,
  confirmed,
  min,
  max,
  decimal,
  maxArrLength,
  minArrLength,
  maxValue,
  minValue,
  postBackUrl,
  emailOrPhone,
  // realPhone,
  maskedPhone,
  iban,
}
