import { App, defineAsyncComponent } from 'vue'

const AuthLogin = defineAsyncComponent(() => import('@m/auth/components/AuthLogin.vue'))
const AuthProvider = defineAsyncComponent(() => import('@m/auth/components/AuthProvider.vue'))
const UserAccessState = defineAsyncComponent(() => import('@m/auth/components/UserAccessState.vue'))

export default {
  install(app: App<Element>): void {
    app
      .component('AuthLogin', AuthLogin)
      .component('AuthProvider', AuthProvider)
      .component('UserAccessState', UserAccessState)
  }
}
