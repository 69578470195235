import { App, defineAsyncComponent } from 'vue'

import LoadingWrapper from './LoadingWrapper.vue'
import ModalTrigger from './modals/ModalTrigger.vue'
import AppIcon from './AppIcon.vue'

const UiSidebar = defineAsyncComponent(() => import('./UiSidebar.vue'))
const UiAccordion = defineAsyncComponent(() => import('./UiAccordion.vue'))
const UiAccordionsList = defineAsyncComponent(() => import('./UiAccordionsList.vue'))
const UiToggler = defineAsyncComponent(() => import('./UiToggler'))
const UiEllipsisContent = defineAsyncComponent(() => import('./UiEllipsisContent.vue'))
const UiPagination = defineAsyncComponent(() => import('./UiPagination.vue'))
const SelectionController = defineAsyncComponent(() => import('./SelectionController'))
const VueAllSelectionController = defineAsyncComponent(() => import('./VueAllSelectionController.vue'))
const VueOrderingController = defineAsyncComponent(() => import('./VueOrderingController.vue'))
const TableScroller = defineAsyncComponent(() => import('./TableScroller.vue'))

const install = (app: App<Element>, prefix = 'Ui'): void => {
  app
    .component(`${prefix}ModalTrigger`, ModalTrigger)
    .component(`${prefix}TableScroller`, TableScroller)
    .component('UiToggler', UiToggler)
    .component('UiSidebar', UiSidebar)
    .component('UiEllipsisContent', UiEllipsisContent)
    .component('UiAccordion', UiAccordion)
    .component('UiAccordionsList', UiAccordionsList)
    .component('LoadingWrapper', LoadingWrapper)
    .component('AppIcon', AppIcon)
    .component('UiPagination', UiPagination)
    .component('SelectionController', SelectionController)
    .component('VueAllSelectionController', VueAllSelectionController)
    .component('VueOrderingController', VueOrderingController)
}

export default {
  install,
}
