import type { RouteRecordRaw } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'

const PRICES_PREFIX = 'cabinet:prices'
const p = (name: string) => PRICES_PREFIX + ':' + name

export const PRICES_VIEW = p('view')
export const PRICES_PREDICT = p('predict')
export const PRICES_WORST = p('worst')

const PricesView = () => import('./views/View.vue')
const PricesPredict = () => import('./views/PredictPrices.vue')
const PricesWorst = () => import('./views/WorstPrices.vue')


export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    prefixRoutes('', [
      route('predict/', PricesPredict, PRICES_PREDICT, {
        meta: {
          title: () => $t('Прогнозована ціни'),
        },
      }),
      route('worst/', PricesWorst, PRICES_WORST, {
        meta: {
          title: () => $t('Максимальні ціни'),
        },
      }),
    ], {
      component: PricesView,
      name: PRICES_VIEW,
      redirect: { name: PRICES_PREDICT }
    }),
  ]
}
