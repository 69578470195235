import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["FilterController"], _mergeProps({
    receive: $props.allProps.receive,
    all: $props.allProps.result && $props.allProps.result.items,
    permanent: $props.permanent,
    parameters: $props.parameters
  }, _ctx.$attrs, {
    loading: $props.allProps.loading || false,
    value: $props.value,
    onInput: $setup.handleFilter
  }), _createSlots({
    append: _withCtx((scope) => [
      _renderSlot(_ctx.$slots, "append", _normalizeProps(_guardReactiveProps(scope)))
    ]),
    _: 2
  }, [
    _renderList(_ctx.$slots, (slot, key) => {
      return {
        name: key,
        fn: _withCtx((scope) => [
          _renderSlot(_ctx.$slots, key, _normalizeProps(_guardReactiveProps(scope)))
        ])
      }
    })
  ]), 1040, ["receive", "all", "permanent", "parameters", "loading", "value"]))
}