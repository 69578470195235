import type { RouteRecordRaw } from 'vue-router'
import { route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'
// import { receiveRoute } from '@m/cabinet/components/views/utils'
// import { communicationApi } from '@/services/communication.service'

const REPORTS_PREFIX = 'cabinet:reports'

export const REPORTS_LIST = REPORTS_PREFIX + ':list'

const List = () => import('./views/List.vue')

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route('list/', List, REPORTS_LIST, {
      meta: {
        title: () => $t('Мої звіти'),
      },
    }),
    { path: '', redirect: { name: REPORTS_LIST } },
  ]
}
