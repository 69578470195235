import { App } from 'vue'
import { createI18n, type I18nOptions } from "vue-i18n"

declare global {
  interface Window {
    language: string
    django: {
      catalog: {
        [key: string]: string
      }
    }
  }
}

const messages = {
  [window.language]: window.django && window.django.catalog || {},
}

type compileMessages = {
  locale: string,
  messages: typeof messages
}

export const compileMessages = (): compileMessages => ({
  locale: window.language,
  messages,
})

// Data-time formats

const NUMERIC = 'numeric' as const
const LONG = 'long' as const
const DATE_CONFIG = {
  year: NUMERIC,
  month: NUMERIC,
  day: NUMERIC,
}
const SHORT_DATE_CONFIG = {
  ...DATE_CONFIG,
  year: '2-digit' as const,
}
const TIME_CONFIG = {
  hour: NUMERIC,
  minute: NUMERIC,
}
const DATETIME_CONFIG = {
  ...DATE_CONFIG,
  ...TIME_CONFIG,
}

const datetimeFormats = {
  [window.language]: {
    short: SHORT_DATE_CONFIG,
    medium: { year: NUMERIC, month: LONG, day: NUMERIC },
    date: DATE_CONFIG,
    time: TIME_CONFIG,
    month: { year: NUMERIC, month: LONG },
    year: { year: NUMERIC },
    datetime: DATETIME_CONFIG,
  },
}
// Number formats
function tryIntlNumberFormatWithUnit(unit = 'kilogram') {
  try {
    return new Intl.NumberFormat(undefined, {
      style: 'unit',
      unit,
    })
  } catch (e) {
    if (e.constructor !== RangeError) {
      throw e
    }
    return null
  }
}
const SHORT = { notation: 'compact', compactDisplay: 'short' }
const SHORT_UNIT = {
  ...SHORT,
  unitDisplay: 'short',
  style: tryIntlNumberFormatWithUnit ? 'unit' : 'decimal',
}
const u = (unit) => ({
  ...SHORT_UNIT,
  unit: tryIntlNumberFormatWithUnit ? unit : null,
})

const numberFormats = {
  [window.language]: {
    short: {
      style: 'decimal',
      ...SHORT,
    } as Intl.NumberFormatOptions,
    long: {
      style: 'decimal',
      ...SHORT,
        unitDisplay: 'long',
      } as Intl.NumberFormatOptions,
    
    currency: {
      style: 'currency',
      currency: 'UAH',
      currencyDisplay: 'symbol',
      currencySign: 'accounting',
      maximumFractionDigits: 0,
    } as Intl.NumberFormatOptions,
    currencyDecimal: {
      style: 'currency',
      currency: 'UAH',
      currencyDisplay: 'symbol',
      currencySign: 'accounting',
      maximumFractionDigits: 2,
    } as Intl.NumberFormatOptions,
    // kilogram: u('kilogram') as Intl.NumberFormatOptions,
    // liter: u('liter') as Intl.NumberFormatOptions,
    // degree: u('degree') as Intl.NumberFormatOptions,
    // kilometer: u('kilometer') as Intl.NumberFormatOptions,
  },
}

const i18nOptions: I18nOptions = {
  ...compileMessages(),
  silentTranslationWarn: true,
  missingWarn: false,
  fallbackWarn: false,
  legacy: false,
  datetimeFormats,
  numberFormats,
}

export const i18n = createI18n(i18nOptions)

export default {
  install(app: App<Element>): void {
    app.use(i18n)
  }
}
