import { BaseResource } from '@/plugins/resource'
import { prefixRoutes } from '@/router/utils'
import { defineAsyncComponent } from 'vue'
import type { RouteRecordRaw } from 'vue-router'

const Receive = defineAsyncComponent(() => import('./Receive.vue'))

export const receiveRoute = (
  resource: BaseResource,
  children: RouteRecordRaw[],
  component = Receive,
  optionalId = false,
  type = '(\\d+)'
): RouteRecordRaw => prefixRoutes(optionalId ? '' : `:id${type}`, children, { component, props: { resource } })
