import type { RouteRecordRaw } from 'vue-router'
import { i18n } from '@/i18n'
import { route } from '@/router/utils'
import { receiveRoute } from '@m/cabinet/components/views/utils'
import type { Options } from '@/router'
import { companyApi } from '@/services/company.service'
import { OFFERS_CREATE } from '../Orders/router'
// import { receiveRoute } from '@m/cabinet/components/views/utils'
// import { communicationApi } from '@/services/communication.service'

const Receive = () => import('@/modules/cabinet/components/views/Receive.vue')

const PROFILE_PREFIX = 'cabinet:company'
const p = (name: string) => PROFILE_PREFIX + ':' + name

export const COMPANY_CREATE = p('create')
export const COMPANY_EDIT = p('edit')
export const COMPANY_VIEW = p('view')
export const COMPANY_BALANCE = p('balance')
export const COMPANY_TRANSACTIONS  = p('transactions')

const Edit = () => import('./views/Edit.vue')
const View = () => import('./views/View.vue')
const BalanceView = () => import('./views/BalanceView.vue')
const TransactionsView = () => import('./views/Transactions/index.vue')

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route('create/', Edit, COMPANY_CREATE, {
      meta: {
        title: () => $t('Створення компанії'),
      },
    }),
    route('balance/', BalanceView, COMPANY_BALANCE, {
      meta: {
        title: () => $t('Баланс підприємства'),
      },
    }),
    route('transactions/', TransactionsView, COMPANY_TRANSACTIONS, {
      meta: {
        title: () => $t('Історія переказів'),
        fallbackRouteName: COMPANY_BALANCE,
      },
    }),
    { path: '', redirect: { name: COMPANY_VIEW } },
    route('edit/', Edit, COMPANY_EDIT, {
      meta: {
        title: () => $t('Редагування компанії'),
      },
    }),

    receiveRoute(
      companyApi.retrieveOwn,
      [
        route('view/', View, COMPANY_VIEW, {
          meta: {
            title: () => $t('Моя компанія'),
          },
        }),
        { path: '', redirect: { name: COMPANY_EDIT } },
      ],
      Receive,
      true
    ),
    { path: '', redirect: { name: OFFERS_CREATE } },
  ]
}
