import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_resource_loader_controller = _resolveComponent("resource-loader-controller")!

  return (_openBlock(), _createBlock(_component_resource_loader_controller, { resource: $props.listResource }, {
    default: _withCtx((allProps) => [
      _createVNode($setup["SearchFilter"], _mergeProps({
        value: $props.filters,
        permanent: $props.permanent,
        parameters: $props.filterParameters,
        onInput: $setup.changeFilters,
        "all-props": allProps
      }, _ctx.$attrs), _createSlots({
        append: _withCtx((scope) => [
          _renderSlot(_ctx.$slots, "append", _normalizeProps(_guardReactiveProps(scope)))
        ]),
        _: 2
      }, [
        _renderList(_ctx.$slots, (slot, key) => {
          return {
            name: key,
            fn: _withCtx((scope) => [
              _renderSlot(_ctx.$slots, key, _normalizeProps(_guardReactiveProps(scope)))
            ])
          }
        })
      ]), 1040, ["value", "permanent", "parameters", "all-props"])
    ]),
    _: 3
  }, 8, ["resource"]))
}